import React,{useState,useEffect} from 'react'
import BackToTop from '../BacktoTop/BackToTop'
import HeaderMain from '../Header/HeaderMain'
import FooterMain from '../Footer/FooterMain'
import HeaderSmallBar from '../Home/HeaderTopSmallBar/HeaderSmallBar'
import AllCourseHeadeSection from './AllCourseHeadeSection'
import AllCourseSection from './AllCourseSection'
import PageLoading from '../PageLoading/PageLoading'
import WhatsappButton from '../Whatsapp/WhatsappButton'

function AllCourseLandingPage() {
   //-------------------------------------------------------------------------
  //Hook States
  //-------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true);

   //-------------------------------------------------------------------------
  //Page Loading
  //-------------------------------------------------------------------------
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div>
      {isLoading ? (
        <PageLoading image="TalentPoolAcademy_logo.png" />
      ) : (
        <>
       <BackToTop/>
       <HeaderSmallBar/>
       <HeaderMain/>
       <AllCourseHeadeSection/>   
       <AllCourseSection/>    
       <WhatsappButton />
       <FooterMain/>  
       </> 
      )}    
    </div>
  )
}

export default AllCourseLandingPage