import React, { useEffect,useState } from "react";
import HeaderSmallBar from "../Home/HeaderTopSmallBar/HeaderSmallBar";
import HeaderMain from "../Header/HeaderMain";
import FooterMain from "../Footer/FooterMain";
import ReviewHeaderSection from "./ReviewHeaderSection";
import RatingSection from "../Home/TestimolsSection/RatingSection";
import ReviewMoreCardSection from "./ReviewMoreCardSection";
import CompanyLogosLandingPage from "../Home/ComapyLogos/CompanyLogosLandingPage";
import BackToTop from "../BacktoTop/BackToTop";
import PageLoading from "../PageLoading/PageLoading";
import WhatsappButton from "../Whatsapp/WhatsappButton";

function ReviewLandingPage() {
  //--------------------------------------------------------------------------------
  //Make sure rendering start from Top
  //--------------------------------------------------------------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


   //-------------------------------------------------------------------------
  //Hook States
  //-------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true);

   //-------------------------------------------------------------------------
  //Page Loading
  //-------------------------------------------------------------------------
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div>
      {isLoading ? (
        <PageLoading image="TalentPoolAcademy_logo.png" />
      ) : (
        <>
      <HeaderSmallBar />
      <HeaderMain />
      <ReviewHeaderSection />
      <div className="review-rating-section-container-background">
        <RatingSection />
      </div>
      <CompanyLogosLandingPage/>
      <ReviewMoreCardSection />
      <WhatsappButton />
      <BackToTop />
      <FooterMain />
      </>
       )}
    </div>
     
  );
}

export default ReviewLandingPage;
