import React, { useState, useEffect, useRef } from 'react';
import Cards from './Cards';
import './Cards.css';
import HeaderMain from '../../Header/HeaderMain';
import FooterMain from '../../Footer/FooterMain';
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import PageLoading from '../../PageLoading/PageLoading';

function BlogAllCards() {
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [categoryTerm, setCategoryTerm] = useState('');
    const [publishedDateTerm, setPublishedDateTerm] = useState('');
    const [showCategoryOptions, setShowCategoryOptions] = useState(false);
    const [showPublishedDateOptions, setShowPublishedDateOptions] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    //----------------------------------------------------------------
    // Page Loading
    //----------------------------------------------------------------
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1200);

        return () => clearTimeout(timer);
    }, []);

    // Refs to track dropdown elements
    const categoryDropdownRef = useRef(null);
    const publishedDateDropdownRef = useRef(null);

    const categoryOptions = ['React', 'CSS', 'HTML', 'JavaScript', 'NodeJS'];
    const publishedDateOptions = ['Today','1 day ago', '1 Week ago', '1 month ago', '3 months ago', '6 months ago', '1 year ago', 'All'];

    const filteredCategoryOptions = categoryOptions.filter(option =>
        option.toLowerCase().includes(categoryTerm.toLowerCase())
    );

    const filteredPublishedDateOptions = publishedDateOptions.filter(option =>
        option.toLowerCase().includes(publishedDateTerm.toLowerCase())
    );

    const handleClickFilter = () => {
        setSearchTerm('');
        setCategoryTerm('');
        setPublishedDateTerm('');
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const handleCategoryChange = (e) => {
        setCategoryTerm(e.target.value);
        setShowCategoryOptions(true);
    };

    const handlePublishedDateChange = (e) => {
        setPublishedDateTerm(e.target.value);
        setShowPublishedDateOptions(true);
    };

    const handleCategoryOptionClick = (option) => {
        setCategoryTerm(option);
        setShowCategoryOptions(false);
    };

    const handlePublishedDateOptionClick = (option) => {
        setPublishedDateTerm(option);
        setShowPublishedDateOptions(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (categoryDropdownRef.current && !categoryDropdownRef.current.contains(event.target)) {
                setShowCategoryOptions(false);
            }
            if (publishedDateDropdownRef.current && !publishedDateDropdownRef.current.contains(event.target)) {
                setShowPublishedDateOptions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
      {isLoading ? (
        <PageLoading image="TalentPoolAcademy_logo.png" />
      ) : (
        <>
            <HeaderMain />
            <div className="cards-container">
                <h1 className="allPost-title">All Posts</h1>

                <div className="Search-and-clear-filter-section-container">
                    <div className="blog-question-header-main">
                        <div className="blog-question-header-content">
                            <div className="blog-question-header-search">
                                {/* Search Input */}
                                <SearchIcon className="blog-search-icon" />
                                <input
                                    type="text"
                                    placeholder="Search Posts"
                                    className="blog-question-no-border"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                                <span className="vertical-line" />

                                {/* Category Input */}
                                <div className="input-wrapper" ref={categoryDropdownRef}>
                                    <input
                                        type="text"
                                        placeholder="Category"
                                        className="category-input"
                                        value={categoryTerm}
                                        onChange={handleCategoryChange}
                                        onClick={() => setShowCategoryOptions(true)}
                                    />
                                    {showCategoryOptions && filteredCategoryOptions.length > 0 && (
                                        <ul className="dropdown-options">
                                            {filteredCategoryOptions.map((option, index) => (
                                                <li key={index} onClick={() => handleCategoryOptionClick(option)}>
                                                    {option}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>

                                <span className="vertical-line" />

                                {/* Published Date Input */}
                                <div className="input-wrapper" ref={publishedDateDropdownRef}>
                                    <input
                                        type="text"
                                        placeholder="Published Date"
                                        className="published-date-input"
                                        value={publishedDateTerm}
                                        onChange={handlePublishedDateChange}
                                        onClick={() => setShowPublishedDateOptions(true)}
                                    />
                                    {showPublishedDateOptions && filteredPublishedDateOptions.length > 0 && (
                                        <ul className="dropdown-options">
                                            {filteredPublishedDateOptions.map((option, index) => (
                                                <li key={index} onClick={() => handlePublishedDateOptionClick(option)}>
                                                    {option}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="clear-filter-section-review-button" onClick={handleClickFilter}>
                        <FilterListIcon className="buttonicons-reset-filter" />
                        &nbsp;Reset Filters
                    </div>
                </div>

                {/* Pass filters to Cards component */}
                <Cards
                    showLimited={false} searchTerm={searchTerm}
                    categoryTerm={categoryTerm} publishedDateTerm={publishedDateTerm}
                />
            </div>
            <FooterMain />
        </>
        )}
    </div>
    );
}

export default BlogAllCards;
