import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faGlobe,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "./Footer.css";

function FooterComponent1() {
  return (
    /*_______________________________________Footer Content 1st component____________________________*/
    <div className="footer_content">
      {/*_______________________________________Footer Content 1st component heading ____________________________*/}
      <div className="footer-1st-paragraph-component-header">
        <div className="footer-heading">Contact Us</div>
      </div>
      {/*_______________________________________Footer Content 1st component paragraph ____________________________*/}
      <div className="width-fix-container">
        <div className="footer-1st-paragraph-component">
          <FontAwesomeIcon icon={faEnvelope} />{" "}
          <div className="footer-1st-component-text-align-to-top">
            Email: info@talentpool-academy.in
          </div>
        </div>
        {/*_______________________________________Footer Content 1st component paragraph ____________________________*/}
        <div className="footer-1st-paragraph-component">
          <FontAwesomeIcon icon={faPhone} />{" "}
          <div className="footer-1st-component-text-align-to-top">
            Phone: +91 7483868010
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterComponent1;
