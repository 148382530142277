import React, { useState } from "react";
import BlogHero from "../BlogImages/BlogHero.png";
import SaveIcon from "@mui/icons-material/Save";
import "./BlogHero.css";
import Editor1 from "../../Editor/Editor1";
import { Modal, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { baseURL } from "../../../http";
import { customAlert } from "../../SweetAlertCommon/Custom";

function BlogHeroSection() {
  // State for modal open/close
  const [open, setOpen] = useState(false);
  const LoginEmail = localStorage.getItem("HTES_user_name");

  // State for input and tags
  const [inputValue, setInputValue] = useState("");
  const [showAddIcon, setShowAddIcon] = useState(false);

  const [headingValue, setHeadingValue] = useState("");
  const [content, setContent] = useState("");
  const [selectedTag, setSelectedTag] = useState([]);
  const [PublisheDate, setPublisheDate] = useState("");
  const [isApproved, setIsApproved] = useState(false);

  const allTags = [
    { title: "React" },
    { title: "JavaScript" },
    { title: "CSS" },
    { title: "HTML" },
    // Add more options as needed
  ];

  // Filtered options to exclude already selected tags
  const availableTags = allTags.filter(
    (tag) => !selectedTag.some((selected) => selected.title === tag.title)
  );

  // Handle input change for autocomplete
  const handleInputChange = (newInputValue) => {
    setInputValue(newInputValue);
    const isNewOption = !availableTags.some(
      (option) => option.title === newInputValue
    );
    setShowAddIcon(isNewOption && newInputValue !== "");
  };

  // Add new item when the Add button is clicked
  const handleAddNewItem = () => {
    if (
      inputValue &&
      !selectedTag.some((option) => option.title === inputValue)
    ) {
      const newOption = { title: inputValue };
      setSelectedTag([...selectedTag, newOption]);
    }
    setInputValue("");
    setShowAddIcon(false);
  };

  // Handle removing a tag from selected tags
  const handleRemoveTag = (tagToRemove) => {
    setSelectedTag(
      selectedTag.filter((tag) => tag.title !== tagToRemove.title)
    );
  };

  const handleSave = async () => {
    try {
      const LoginEmail = localStorage.getItem("HTES_user_name");
  
      const contentEditor = document.getElementById("edt");
      const fullContent = contentEditor.innerHTML;
  
      // Convert images to base64 strings within the HTML content
      const updatedContent = await processImagesInContent(fullContent); 
  
      // Strip HTML tags but preserve image URLs
      const normalContent = extractTextWithImages(contentEditor);
  
      const response = await axios.post(`${baseURL}/blogs`, {
        heading: headingValue,
        content: updatedContent, // Content with HTML and images (base64 or URLs)
        normalContent: normalContent, // Content without HTML but with image URLs
        category: selectedTag.map((tag) => tag.title),
        Created_date: PublisheDate,
        IsApproved: isApproved,
        Created_by: LoginEmail,
      });
  
      console.log("Blog post saved successfully:", response.data);
      handleClose();
      customAlert("", "Blog post saved successfully", "success");
    } catch (error) {
      customAlert("", "There was an error saving the blog post", "warning");
      console.error("There was an error saving the blog post:", error);
    }
  };
  
  // Helper function to strip HTML while keeping image URLs
  const extractTextWithImages = (contentEditor) => {
    const clonedNode = contentEditor.cloneNode(true); // Clone the content editor DOM to manipulate safely
    const images = clonedNode.querySelectorAll('img');
  
    // Replace images with their URLs
    images.forEach((img) => {
      const imgUrl = img.src;
      const imgTextNode = document.createTextNode(`[Image: ${imgUrl}]`); // Create text node with the image URL
      img.replaceWith(imgTextNode); // Replace the img tag with the image URL as text
    });
  
    // Strip all remaining HTML tags
    return clonedNode.textContent || clonedNode.innerText || "";
  };
  

// Helper function to convert images to base64 in the content
const processImagesInContent = async (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const images = doc.querySelectorAll('img');

    // Convert images to base64
    for (const img of images) {
        if (img.src.startsWith('data:')) {
            // Already base64, no need to convert
            continue;
        }
        const base64Image = await convertImageToBase64(img.src);
        img.src = base64Image;
    }

    return doc.body.innerHTML;
};

// Convert image URL to base64
const convertImageToBase64 = (imageUrl) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            resolve(dataURL);
        };
        img.onerror = reject;
        img.src = imageUrl;
    });
};


  const handleOpenModal = () => {
    if (LoginEmail) {
      setOpen(true); // Open modal if logged in
    } else {
      customAlert(
        "", // No title needed, you can adjust as per your design
        "Please Login To Add Your Interview Questions",
        "warning" // Alert type: warning, success, etc.
      );
    }
  };
  const handleClose = () => {
    setOpen(false);
    setInputValue("");
    setSelectedTag([]);
  };

  return (
    <section className="blog-hero-section">
      <div className="blog-hero-content">
        <div className="blog-hero-text">
          <h1 className="blog-hero-title">Welcome to TPA Blog</h1>
          <p className="blog-hero-subtitle">
            Sharing thoughts, ideas, and stories. Discover new insights and
            perspectives. Stay updated with the latest trends.
          </p>
          <button className="blog-hero-button" onClick={handleOpenModal}>
            Add Blog
          </button>
        </div>
        {/* <div className="blog-hero-image">
          <img src={BlogHero} alt="Blogging" />
        </div> */}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-blog-modal"
        aria-describedby="modal-for-adding-blog"
      >
        <div className="modal-box">
          <div>
            <IconButton
              id="button-zoom"
              className="enquiry-form-addeducationalMapmodalcancel"
              onClick={handleClose}
            >
              <CloseIcon className="enquiry-form-addeducationalMapmodalcancelX" />
            </IconButton>
          </div>

          {/* Flex container for h2 and Autocomplete */}
          <div className="modal-header-row">
            <h2 className="modal-title">Add a New Blog</h2>
          </div>

          {/* Editor1 component inside the modal */}
          <Editor1
            isHeadingRequird={true}
            is_tag_required={true}
            isAutocomplete={true}
            desktopHeight="200px"
            tabHeight="175px"
            mobileHeight="150px"
            return_location="/dashboard"
            editID={"editID"}
            showHyperlink={true}
            showImageInsert={true}
            showCodeViewer={true}
            showButtonInsert={false}
            setContent={setContent}
            setHeading={setHeadingValue}
            label="Select Category"
            header_label="Heading"
            inputValue={inputValue}
            setInputValue={setInputValue}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            showAddIcon={showAddIcon}
            handleInputChange={handleInputChange}
            handleAddNewItem={handleAddNewItem}
            tagOptions={availableTags}
            handleRemoveTag={handleRemoveTag}
            setHeadingValue={setHeadingValue}
          />

          <div className="modal-actions">
            <Button
              variant="contained"
              className="BlogSave"
              onClick={handleSave}
            >
              <SaveIcon className="buttoniconcommon" />
              Save Blog
            </Button>
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default BlogHeroSection;
