import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faCalendarAlt,
  faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons";
import "./Corporate.css";

const CorporateTrainingFeatures = () => {
  const features = [
    {
      text: "Customized Training",
      subText: "Tailored to your needs",
      icon: faCogs,
    },
    {
      text: "Flexible Scheduling",
      subText: "Convenient for your team",
      icon: faCalendarAlt,
    },
    {
      text: "Expert Instructors",
      subText: "Learn from the best",
      icon: faChalkboardTeacher,
    },
  ];

  const cardRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the card is in view
    );

    cardRefs.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      cardRefs.current.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, []);

  return (
    <div className="corporate-features-main">
      <div className="corporate-training">
        <h2>Corporate Training Features</h2>
        <p>
          Enhance your team's skills with our comprehensive training programs.
        </p>
        <div className="features-cards">
          {features.map((feature, index) => (
            <div
              key={index}
              className="card"
              ref={(el) => (cardRefs.current[index] = el)}
            >
              <FontAwesomeIcon
                icon={feature.icon}
                className="corporate-feature-icon"
              />
              <h3>{feature.text}</h3>
              <p className="sub-heading">{feature.subText}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CorporateTrainingFeatures;
