import React, { useState, useEffect, useRef } from "react";
import "./HeaderMain.css";
import SearchIcon from "@mui/icons-material/Search";
import MeatDot from "./MeatDot";
import HamburgerMenu from "./HamburgerMenu";
import DropdownMenu from "./DropdownMenu";
import axios from "axios";
import { baseURL } from "../../http";
import { useNavigate } from "react-router-dom";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import Logo from "./HeaderImage/TalentPoolAcademy_logo.png"

//--------------------------------------------------------------------------------
// Header items
//--------------------------------------------------------------------------------
const NavItems = [
  { name: "Internship", Link: "/internship" },
  { name: "Job Portal", Link: "/job_portal" },
  { name: "Corporate Training", Link: "/Corporate" },
  { name: "Teach on TPA", Link: "/teachontpa" },
  { name: "Hire Talent", Link: "/hireTalent" },
];

function HeaderItems() {
  //--------------------------------------------------------------------------------
  // states
  //--------------------------------------------------------------------------------
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 960);
  const [searchActive, setSearchActive] = useState(false);
  const [structuredData, setStructuredData] = useState({});
  const [isSearchClick, setIsSearchClick] = useState(false);
  const searchInputRef = useRef(null);
  const searchContainerRef = useRef(null); // Ref for the search container
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  //--------------------------------------------------------------------------------
  // Get all the couse based on the published condition (search the course)
  //--------------------------------------------------------------------------------
  useEffect(() => {

    axios
      .get(baseURL + "/getcourse")
      .then((response) => {
        const data = response.data;

        if (data && Array.isArray(data)) {
          const filteredData = data.filter((item) => item.isPublished === "1");

          const categoryMap = {};

          filteredData.forEach((item) => {
            if (!categoryMap[item.Category]) {
              categoryMap[item.Category] = {};
            }
            if (!categoryMap[item.Category][item.SubCategory]) {
              categoryMap[item.Category][item.SubCategory] = [];
            }
            categoryMap[item.Category][item.SubCategory].push(item.CourseName);
          });

          setStructuredData(categoryMap);
        } else {
          console.error("Unexpected response data:", data);
          setStructuredData({});
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setStructuredData({});
      });

  }, []);

  //--------------------------------------------------------------------------------
  // Resize screen handle function
  //--------------------------------------------------------------------------------
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 960);
  };

  //--------------------------------------------------------------------------------
  // chnage the component alignment based on the resize screen
  //--------------------------------------------------------------------------------
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //--------------------------------------------------------------------------------
  // click the search bar
  //--------------------------------------------------------------------------------
  const handleClick = () => {
    setIsSearchClick(true);
  };

  //--------------------------------------------------------------------------------
  // keep the focuss on the search bar
  //--------------------------------------------------------------------------------
  const handleSearchFocus = () => {
    setSearchActive(true);
  };

  //--------------------------------------------------------------------------------
  // Detect clicks outside of the search container
  //--------------------------------------------------------------------------------

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setSearchActive(false);
        setIsSearchClick(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //--------------------------------------------------------------------------------
  // remove the focus when click otsid ethe search
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (isSearchClick && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isSearchClick]);

  //--------------------------------------------------------------------------------
  //filter the course name only from the data
  //--------------------------------------------------------------------------------
  const filteredCourses = () => {
    let filtered = {};
    for (const category in structuredData) {
      for (const subCategory in structuredData[category]) {
        const filteredSubCategory = structuredData[category][
          subCategory
        ].filter((course) =>
          course.toLowerCase().includes(searchQuery.toLowerCase())
        );
        if (filteredSubCategory.length > 0) {
          if (!filtered[category]) filtered[category] = {};
          filtered[category][subCategory] = filteredSubCategory;
        }
      }
    }
    return filtered;
  };

  //--------------------------------------------------------------------------------
  // click the seached course to navigate to the course page
  //--------------------------------------------------------------------------------
  const handleCourseClick = (courseName, category) => {
    navigate(`/course/${category}/${courseName}`);
    setSearchQuery(courseName);
    setSearchActive(false);
    setIsSearchClick(false);
  };

  const searchResults = filteredCourses();

  return (
    <div className="headerItems">
      {isMobile ? (
        <>
          {/* ______________Mobile Menu bar____________ */}
          <HamburgerMenu NavItems={NavItems} subCategories={structuredData} />
          {!isSearchClick && (
            <a href="/home">
              <img
                src={Logo}
                alt="TPA LOGO"
                className="logo"
              />
            </a>
          )}
          {/* ______________Search bar foe mobile____________ */}
          <div className="mobileview-search-and-meta-dot">
            {!isSearchClick && (
              <SearchIcon
                className="search-icon-for-mobile-view"
                onClick={handleClick}
              />
            )}
            {isSearchClick && (
              <div
                className="searchContainer"
                onFocus={handleSearchFocus}
                ref={searchContainerRef}
              >
                <SearchIcon className="searchIcon" />
                <input
                  type="text"
                  placeholder={`${!searchActive ? "Search..." : "Search Courses"
                    }`}
                  className="searchInput"
                  ref={searchInputRef}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {/* ______________Seach functionality____________ */}
                {searchQuery && searchActive && (
                  <div className="searchResultsContainer">
                    {Object.keys(searchResults).map((category, index) => (
                      <div key={index}>
                        {Object.keys(searchResults[category]).map(
                          (subCategory, subIndex) => (
                            <div key={subIndex}>
                              {searchResults[category][subCategory].map(
                                (courseName, courseIndex) => (
                                  <div
                                    key={courseIndex}
                                    onMouseDown={() =>
                                      handleCourseClick(courseName, category)
                                    }
                                    className="search-functionality-course-name-container"
                                  >
                                    <div>
                                      <LocalLibraryIcon className="buttoniconcommon-search-functionality" />
                                    </div>
                                    <div>{courseName}</div>
                                  </div>
                                )
                              )}
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {/* ______________Three dot icon for header____________ */}
            <MeatDot />
          </div>
        </>
      ) : (
        <>
          {/* ______________TPA logo____________ */}
          <a href="/home">
            <img
              src={Logo}
              alt="TPA LOGO"
              className="logo"
            />
          </a>
          {/* ______________Drpdown menu____________ */}
          <div className="ddMenuClass">
            <DropdownMenu
              categories={Object.keys(structuredData)}
              subCategories={structuredData}
            />
          </div>
          <div
            className="searchContainer"
            onFocus={handleSearchFocus}
            ref={searchContainerRef}
          >
            {/* ______________Search bar____________ */}
            <SearchIcon className="searchIcon" />
            <input
              type="text"
              placeholder={`${!searchActive ? "Search..." : "Search Courses"}`}
              className={`searchInput ${searchActive ? "size" : ""}`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {/* ______________search  the Course in the Desktop view____________ */}
            {searchQuery && searchActive && (
              <div className="searchResultsContainer">
                {Object.keys(searchResults).map((category, index) => (
                  <div key={index} className="searchResultsContainer1">
                    {Object.keys(searchResults[category]).map(
                      (subCategory, subIndex) => (
                        <div key={subIndex} className="searchResultsContainer2">
                          {searchResults[category][subCategory].map(
                            (courseName, courseIndex) => (
                              <div
                                key={courseIndex}
                                onMouseDown={() =>
                                  handleCourseClick(courseName, category)
                                }
                                className="search-functionality-course-name-container"
                              >
                                <div>
                                  <LocalLibraryIcon className="buttoniconcommon-search-functionality" />
                                </div>
                                <div>{courseName}</div>
                              </div>
                            )
                          )}
                        </div>
                      )
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* ______________Tabs of the Header____________ */}
          <div className={`navLinks ${searchActive ? "hide" : ""}`}>
            {NavItems.map((item, index) => (
              <a key={index} href={`${item.Link}`} className="navItem">
                {item.name}
              </a>
            ))}
          </div>
          {/* ______________Three dot icon____________ */}
          <MeatDot />
        </>
      )}
    </div>
  );
}

export default HeaderItems;
