import React from 'react';
import CardsMain from './BlogCards/CardsMain';
import HeaderMain from '../Header/HeaderMain';
import FooterMain from '../Footer/FooterMain';
import BlogHeroSection from './BlogHero/BlogHeroSection';
import BackToTop from '../BacktoTop/BackToTop';
import ComingSoon from "./ComingSoon"
import WhatsappButton from '../Whatsapp/WhatsappButton';

const BlogMain = () => {
  return (
    <div>
      <HeaderMain/>
      <BlogHeroSection/>
      <CardsMain/>
      <FooterMain/>
      <WhatsappButton />
      <BackToTop/>
      {/* <ComingSoon/> */}
    </div>
  )
}

export default BlogMain
