import React, { useState, useEffect } from 'react';
import Cards from './Cards';
import './Cards.css'; // Make sure to import your CSS file
import { Link } from 'react-router-dom';
import PageLoading from '../../PageLoading/PageLoading';

function CardsMain() {

    const [isLoading, setIsLoading] = useState(true);

    //----------------------------------------------------------------
    // Page Loading
    //----------------------------------------------------------------
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            {isLoading ? (
                <PageLoading image="TalentPoolAcademy_logo.png" />
            ) : (
                <>
                    <div className="cards-container">
                        <div className="header-container">
                            <h1 className="header-title">Latest posts</h1>
                            <Link to="/all-posts" className="view-more-link">
                                VIEW ALL POSTS <span className="arrow-icon">→</span>
                            </Link>
                        </div>
                        <Cards showLimited={true} />
                    </div>
                </>
            )}
        </div>
    );
}

export default CardsMain;
