import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { styled, useTheme } from "@mui/system";

const TimerTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginLeft: theme.breakpoints.down('md') ? -10 : -43,
  color: "black",
  fontWeight: "bold"
}));

function QuizTime({ time, loadingTimeoff,timeLeft,setTimeLeft,reportModalOpen}) {
  const theme = useTheme();
  //const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  //console.log('loading', loadingTimeoff);

  //const initialTimeLeft = time * 60;
  const [initialTime, setInitialTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);

  

  useEffect(() => {
    // Set up the interval to update the timer every second
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 0 || loadingTimeoff || reportModalOpen) { // Stop the timer if time is 0, loadingTimeoff is true, or reportModalOpen is true
          clearInterval(interval);
          return prevTime;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Clear interval on unmount or when loadingTimeoff or reportModalOpen becomes true
    return () => clearInterval(interval);
  }, [loadingTimeoff, reportModalOpen]);




  // Save the initial time when the component mounts
  useEffect(() => {
    const initialTime = new Date().getTime();
    setInitialTime(initialTime);
  }, []);




  // Calculate the remaining time based on the difference between the current time and the initial time
  useEffect(() => {
    if (!loadingTimeoff && initialTime !== null ) {
      const currentTime = new Date().getTime();
      const elapsedTimeInSeconds = Math.floor((currentTime - initialTime) / 1000);
      const remainingTime = initialTime + (timeLeft * 1000) - currentTime;
      if (remainingTime > 0) {
        setTimeLeft(Math.floor(remainingTime / 1000));
        setElapsedTime(elapsedTimeInSeconds);
      } else {
        setTimeLeft(0);
      }
    }
  }, [loadingTimeoff, initialTime]);

  // Format time into hours, minutes, and seconds
  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;

  // Add leading zero if seconds is less than 10
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedHours = hours < 10 ? `0${hours}` : hours;

  return (
    <>
      <div>
        <TimerTypography variant="h6" sx={{ textAlign: 'center', color: timeLeft <= 120 ? 'red' : 'black', fontWeight: "bold" }}>
          Time Left: {formattedHours}<span style={{ color: 'blue', fontSize: '8px' }}>H</span>:{formattedMinutes}<span style={{ color: 'blue', fontSize: '8px' }}>M</span>:{formattedSeconds}<span style={{ color: 'blue', fontSize: '8px' }}>S</span>
        </TimerTypography>
      </div>
    </>
  );
}

export default QuizTime;
