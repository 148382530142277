import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from './Components/Home/LandingPage';
import SEOComponnet from './Components/SEO/SEOComponnet';
import TeachUsOnTPA from './Components/TeachUSonTPA/TeachUsOnTPAMain';
import SignIn from './Components/Common/SignIn';
import SignUp from './Components/Common/SignUP';
import InternshipMain from './Components/Internship/InternshipMain';
import HireTalentMain from './Components/HireTalent/HireTalentMain';
import FooterMain from './Components/Footer/FooterMain';
import ContactMain from './Components/ContactUs/ContactMain';
import AboutMain from './Components/AboutUs/AboutMain';
import CourseCard from './Components/CourseCard/CourseCard';
import CourseTemplateMainPage from './Components/CoursePage/CourseTemplateMainPage';
import JobMain from './Components/JobPortal/JobMain';
import ReviewCard from './Components/Home/TestimolsSection/ReviewCard';
import CorporateMain from './Components/CorporateTraining/CorporateMain';
import ReviewLandingPage from './Components/Review/ReviewLandingPage';
import AllCourseLandingPage from './Components/AllCoursePage/AllCourseLandingPage';
import SignOut from './Components/Common/SignOut';
import ProfileManagement from './Components/ProfileManagement/Pm_LandingPage';
import JobDetailsPage from './Components/JobPortal/JobDetailsPage';
// import ComingSoon from './Components/Blog/ComingSoon';
import InterViewQuestionLandingPage from './Components/InterViewQuestion/InterViewQuestionLandingPage';
import ResumeMain from './Components/ResumeTemplate/ResumeMain';
import TrackWebsiteMain from './Components/TrackWebsite/TrackWebsiteMain';
// import ProfileTemplate from './Components/ResumeTemplate1.js/ProfileTemplate';
// import TemplateMain from './Components/ResumeTemplate2.js/TemplateMain';
import BlogMain from './Components/Blog/BlogMain';
import BlogContent from './Components/Blog/BlogCards/BlogContent';
import BlogAllCards from './Components/Blog/BlogCards/BlogAllCards';
import Editor1 from './Components/Editor/Editor1';
import ExamList from './Components/PopularExams/PopularExams';

import ExamInfo from './Components/PopularExams/ExamInfo/ExamInfoMain';
import ExamDetails from './Components/PopularExams/ExamDetailsContentPage/ExamDetails';
import ForgotPassword from './Components/Common/ForgotPassword';
import ExploreMain from './Components/PopularExams/ExploreAll/ExploreMain';
import QuizDashboard from './Components/QuizPage/QuizDashboard1';
import ViewTestSeries from './Components/ViewTestSeries/ViewSeriesMain';



function Main() {
  return (
    
    <Router>

      <SEOComponnet url="https://talentpool-academy.in/" />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/teachontpa" element={<TeachUsOnTPA />} />
        <Route path="/internship" element={<InternshipMain/>} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/register" element={<SignUp/>} />
        <Route path="/hireTalent" element={<HireTalentMain />} />
        <Route path="/footer" element={<FooterMain/>}/>
        <Route path="/contact" element={<ContactMain/>}/>
        <Route path="/about" element={<AboutMain/>}/>
        <Route path="/card" element={<CourseCard/>}/>
        <Route path="/enquiry" element={<ReviewCard/>}/>
        <Route path="/course/:category/:courseName" element={<CourseTemplateMainPage />} />
        <Route path="/job_portal" element={<JobMain/>}/>
        <Route path="/job/:id" element={<JobDetailsPage/>} />
        <Route path='/Corporate' element={<CorporateMain/>}/>
        <Route path='/AllCourse' element={<AllCourseLandingPage/>}/>
        <Route path='/review' element={<ReviewLandingPage/>}/>
        <Route path='/signout' element={<SignOut/>}/>
        <Route path='/update_profile' element={<ProfileManagement/>}/>
        <Route path='/blog' element={<BlogMain/>}/>
        <Route path='/interview_questions' element={<InterViewQuestionLandingPage/>} />
        {/* <Route path='/resume' element={<ResumeMain/>}/> */}
        {/* <Route path='/sample' element={<ProfileTemplate/>}/>
        <Route path='/resume1' element={<TemplateMain/>}/> */}
        <Route path="/post/:postId" element={<BlogContent />} />
        <Route path="/all-posts" element={<BlogAllCards/>}/>
        <Route path="/editor" element={<Editor1 />} />
        <Route path='/forgot_password' element={<ForgotPassword/>}/>
        {/* <Route path='/examdetails/:examId' element={<ExamDetails/>}/>
        <Route path="/ExamIntro/:examId" element={<ExamInfo/>}/>
        <Route path="explore" element={<ExploreMain/>}/>
        <Route path='quiz' element={<QuizDashboard/>}/>
        <Route path="exam" element={<ExamList/>}/>
        <Route path="viewseries" element={<ViewTestSeries/>}/> */}
     

       
      </Routes>
        
      <TrackWebsiteMain/>
    </Router>
  )
}

export default Main;