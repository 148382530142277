import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';


export default function PmCommonFunction() {
  return (
    <>
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-multiline-flexible"
          label="Multiline"
          multiline
          maxRows={4}
        />
       
      </div>
    </Box>
        <TextField
        fullWidth
          id="outlined-textarea"
          label="Multiline Placeholder"
          placeholder="Placeholder"
          multiline
        />
    </>
  );
}


// --------------------------------------------------
//  For Education Modal
// --------------------------------------------------
export const educationOptions = [
  "Doctorate/PhD",
  "Masters/Post-Graduation",
  "Graduation/Diploma",
  "HSC",
  "SSLC",

  // { value: "Doctorate/PhD", label: "Doctorate/PhD" },
  //   { value: "Masters/Post-Graduation", label: "Masters/Post-Graduation" },
  //   { value: "Graduation/Diploma", label: "Graduation/Diploma" },

  // Add more options as needed
];
export const universityOptions = [
  "Harvard University",
  "Stanford University",
  "Massachusetts Institute of Technology",
  "University of California, Berkeley",
  "California Institute of Technology",
  // Add more options as needed
];

export const educationSpecializationOptions = [
  "Agricultural Engineering",
  "IT Engineering",
  "Civil Engineering",
  "Mechanical Engineering",
  "Electrical Engineering",
  "Chemical Engineering",
  "Biomedical Engineering",
  "Environmental Engineering",
  "Aerospace Engineering",
  "Industrial Engineering",
  "Software Engineering",
  "Nuclear Engineering",
  "Petroleum Engineering",
  "Structural Engineering",
  "Materials Engineering",
  "Marine Engineering",
  "Automotive Engineering",
  "Robotics Engineering",
  "Mining Engineering",
  "Geotechnical Engineering",
];

export const educationCourseOptions = [
 "BE",
  "ME",
  "MBA",
  "MTech",
  "BTech",
  "MSc",
  "BSc",
  "BBA",
  "BCom",
  "MCom",
  "BCA",
  "MCA",
  "LLB",
  "LLM",
  "MBBS",
  "BDS",
  "BPharm",
  "MPharm",
  "BArch",
  "MArch",
  "BEd",
  "MEd",
  "PhD",
  "Diploma in Engineering",
  "Diploma in Pharmacy",
  "BSc Nursing",
  "BPT",
  "MPT",
  "BHM",
  "BFA",
  "MFA",
  "BDesign",
  "MDesign",
  "BMM",
  "BJMC",
  "MJMC"
];

export const languageOptions = [
  "English",
  "Tamil",
  "Kannada",
  "Telugu",
  "Hindi",
  // Add more options as needed
];



//-----------------------------------------------------
// Career Profile Modal
//-----------------------------------------------------
export const locationsPreferred = [
  { value: "Amaravati", label: "Amaravati" },
  { value: "Itanagar", label: "Itanagar" },
  { value: "Dispur", label: "Dispur" },
  { value: "Patna", label: "Patna" },
  { value: "Raipur", label: "Raipur" },
  { value: "Panaji", label: "Panaji" },
  { value: "Gandhinagar", label: "Gandhinagar" },
  { value: "Shimla", label: "Shimla" },
  { value: "Srinagar", label: "Srinagar" },
  { value: "Ranchi", label: "Ranchi" },
  { value: "Bengaluru", label: "Bengaluru" },
  { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
  { value: "Bhopal", label: "Bhopal" },
  { value: "Mumbai", label: "Mumbai" },
  { value: "Imphal", label: "Imphal" },
  { value: "Shillong", label: "Shillong" },
  { value: "Aizawl", label: "Aizawl" },
  { value: "Kohima", label: "Kohima" },
  { value: "Bhubaneswar", label: "Bhubaneswar" },
  { value: "Chandigarh", label: "Chandigarh" },
  { value: "Jaipur", label: "Jaipur" },
  { value: "Gangtok", label: "Gangtok" },
  { value: "Chennai", label: "Chennai" },
  { value: "Hyderabad", label: "Hyderabad" },
  { value: "Agartala", label: "Agartala" },
  { value: "Lucknow", label: "Lucknow" },
  { value: "Dehradun", label: "Dehradun" },
  { value: "Kolkata", label: "Kolkata" },
];



export const keySkillOptions=[
  { value: "React", label: "React" },
  { value: "CSS", label: "CSS" },
  { value: "JavaScript", label: "JavaScript" },
  { value: "Angular", label: "Angular" },
  { value: "Catia", label: "Catia" },
  { value: "PHP", label: "PHP" },
  { value: "Laravel", label: "Laravel" },
  { value: "SAP", label: "SAP" },
  { value: "Devops", label: "Devops" },
];