import React, { useState } from "react";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import SaveIcon from "@mui/icons-material/Save";
import { TextField, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import UpdateIcon from "@mui/icons-material/Update";

function Stscroll7ITSkillsAddModal({
  itData,
  setITData,
  handleItemDelete,
  fetchData,
  setModalStatus,
  callPercentage,
}) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  const userEmail = localStorage.getItem("HTES_user_id") || "";

  const [invalidFields, setInvalidFields] = useState({});
  //--------------------------------------------------------------------------------
  // Save Data API
  //--------------------------------------------------------------------------------
  const saveITData = () => {
    const updates = {
      email: userEmail,
      id: itData.id,
      skills: itData.skills,
      version: itData.version,
      lastUsed: itData.lastUsed,
      itExpYear: itData.itExpYear,
      itExpMonth: itData.itExpMonth,
    };

    // Define required fields for validation
    const requiredFields = [
      "skills",
      "version",
      "lastUsed",
      "itExpYear",
      "itExpMonth",
    ];

    // Validate required fields
    const newInvalidFields = {};
    for (const field of requiredFields) {
      if (!itData[field]) {
        newInvalidFields[field] = true;
      }
    }

    if (Object.keys(newInvalidFields).length > 0) {
      setInvalidFields(newInvalidFields);
      return;
    }

    setInvalidFields({});

    const api =
      itData.id !== 0 ? `updateItSkills/${itData.id}` : "createITSkills";
    const alert = itData.id !== 0 ? `Edited` : "Added";

    fetch(`${baseURL}/${api}`, {
      method: "POST",
      body: JSON.stringify(updates),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          customAlert("", `Item ${alert}`, "success");
          setModalStatus(false);
          callPercentage();
          console.log("Item updated successfully");
        } else {
          console.error("Failed to update item");
        }
      })
      .then(fetchData())
      .catch((error) => {
        console.error("Item updating error:", error);
      });
  };

  return (
   
      <div className="profileModal">
        <span className="pmTabHeadings2">IT Skills</span> 
        <div className="pmModalContainer-it-skills">
        
          {/* _____________________________ Heading    _______________________ */}
          <div className="pmModHeader2">
            {itData.id != 0 && (
              <div className="deleteIconAtpmModal">
                <Tooltip title="Delete">
                  <IconButton
                    variant="contained"
                    color="error"
                    onClick={() => handleItemDelete(itData.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          <div>
            {/* _____________________________ Software Name _______________________ */}
            <div>
              <TextField
                label="Skill / software name"
                fullWidth
                value={itData.skills || ""}
                onChange={(e) =>
                  setITData({ ...itData, skills: e.target.value })
                }
                InputProps={{
                  className: "input_Custom_Style",
                }}
                error={invalidFields.skills}
                helperText={invalidFields.skills ? "* mandatory" : ""}
              />
             
            </div>
          </div>
    
            {/* _____________________________ Software Version  _______________________ */}
            <div className="pmModField1">
              <TextField
                label="Software Version"
                fullWidth
                value={itData.version}
                onChange={(e) =>
                  setITData({ ...itData, version: e.target.value })
                }
                InputProps={{
                  className: "input_Custom_Style",
                }}
                error={invalidFields.version}
                helperText={invalidFields.version ? "* mandatory" : ""}
              />
            </div>
          
            {/* _____________________________    Last Used     _______________________ */}
            <div>
              <TextField
                label="Last Used"
                fullWidth
                value={itData.lastUsed || ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d{0,4}$/.test(inputValue)) {
                    setITData({ ...itData, lastUsed: inputValue });
                  }
                }}
                InputProps={{
                  className: "input_Custom_Style",
                  inputProps: {
                    style: { textAlign: "left" }, // Align text to the right
                  },
                  endAdornment: (
                    <InputAdornment position="end">Year</InputAdornment>
                  ),
                  inputComponent: "input",
                }}
                error={invalidFields.lastUsed}
                helperText={invalidFields.lastUsed ? "* mandatory" : ""}
              />
            </div>
       
        
          {/* _____________________________    Total Experience  _______________________ */}
          <div className="experience-container">

            <div className="experience-fields">
              <div className="pmBasFields">
                <TextField
                  label="Experience Year(s)"
                  fullWidth
                  value={itData.itExpYear || ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setITData({ ...itData, itExpYear: inputValue });
                    }
                  }}
                  InputProps={{
                    className: "input_Custom_Style",
                    inputProps: {
                      style: { textAlign: "left" }, // Align text to the right
                    },
                    endAdornment: (
                      <InputAdornment position="end">Year(s)</InputAdornment>
                    ),
                    inputComponent: "input",
                  }}
                  error={invalidFields.itExpYear}
                  helperText={invalidFields.itExpYear ? "* mandatory" : ""}
                />
              </div>

              <div className="pmBasFields">
                <TextField
                  label="Experience Month(s)"
                  fullWidth
                  value={itData.itExpMonth || ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue) && inputValue <= 12) {
                      setITData({ ...itData, itExpMonth: inputValue });
                    }
                  }}
                  InputProps={{
                    className: "input_Custom_Style",
                    inputProps: {
                      style: { textAlign: "left" }, // Align text to the right
                    },
                    endAdornment: (
                      <InputAdornment position="end">Month(s)</InputAdornment>
                    ),
                    inputComponent: "input",
                  }}
                  error={invalidFields.itExpMonth}
                  helperText={invalidFields.itExpMonth ? "* mandatory" : ""}
                />
              </div>
            </div>

           
          </div>
        </div>
        {/* _____________________________     Save  &  Cancel   _______________________ */}
        <div className="createteslayoutmodel6-skills-modal">
          <Button
            id="button-zoom"
            variant="contained"
            color="success"
            onClick={saveITData}
          >
            {itData.id !== 0 ? (
              <>
                <UpdateIcon className="buttonicons" /> Update
              </>
            ) : (
              <>
                <SaveIcon className="buttonicons" /> Save
              </>
            )}
          </Button>
        </div>
      </div>
   
  );
}

export default Stscroll7ITSkillsAddModal;
