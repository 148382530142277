import React, { useState, useEffect, useCallback } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import GoogleIcon from "@mui/icons-material/Google";
import Divider from "@mui/material/Divider";
import { baseURL } from "../../http";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import {
  getDeviceInfo,
  getCurrentDateTime,
  GoogleSignin,
  getLocationInfo
} from "./Utilities_Function";
import { customAlert } from "../SweetAlertCommon/Custom";



const defaultTheme = createTheme();

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [signupdata, setsignupdata] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
    usertype: "General",
    status: "",
    last_Login_time: null,
    last_login_device_name: null,
    error: "",
    Longitude: null,
    Latitude: null,
    PinCode_Login: null,
    Current_Location: null,
  });
  const [errors_V, set_serrors_V] = useState({});
  const [acceptTerms, setAcceptTerms] = useState(false);

  console.log('signupdata',signupdata)

  // Function to handle changes in input fields
  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    if (name === "mobile" && !/^\d{0,10}$/.test(value)) {
      setsignupdata((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setsignupdata({
        ...signupdata,
        [event.target.name]: event.target.value,
      });
    }
  }, [signupdata]);

  // Function to handle form submission
  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    const validationErrors = {};

    const requiredFields = ["name", "email", "password", "mobile", "usertype"];
    requiredFields.forEach((field) => {
      if (!signupdata[field]) {
        validationErrors[field] = "This field is required";
      }
    });

    if (signupdata.mobile && !/^\d{10}$/.test(signupdata.mobile)) {
      validationErrors.mobile = "Phone number must have 10 digits";
    }

    if (
      signupdata.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signupdata.email)
    ) {
      validationErrors.email = "Enter a valid email address";
    }

    set_serrors_V(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      
      RegisterUser(baseURL + "/register", [signupdata]);
    }
  }, [signupdata]);

  // Function to handle Google sign-in
  const handleGoogleLogin = useCallback(() => {
    GoogleSignin()
      .then((user) => {
        signupdata.email = user.email;
        signupdata.name = user.displayName;
        signupdata.usertype = "General";
        localStorage.setItem("HTES_user_id", signupdata.email);
        localStorage.setItem("HTES_user_name",  signupdata.name);
        RegisterUser(baseURL + "/register_google", signupdata);
      })
      .catch((error) => {
        console.error("Sign-in error:", error);
      });
  }, [signupdata]);

  // Function to handle checkbox state change
  const handleCheckboxChange = useCallback((event) => {
    setAcceptTerms(event.target.checked);
  }, []);

  // Function to toggle password visibility
  const handleClickShowPassword = useCallback(() => {
    setShowPassword((show) => !show);
  }, []);

  const handleMouseDownPassword = useCallback((event) => {
    event.preventDefault();
  }, []);

  useEffect(() => {
    document.title = "SignUp";
    if (signupdata.last_login_device_name == null) {
      signupdata.last_login_device_name  = getDeviceInfo();
    }
  
    if (signupdata.last_Login_time == null) {
      signupdata.last_Login_time  = getCurrentDateTime();
    }

    async function handleSignupData() {
      if (signupdata.Longitude == null) {
        const location = await getLocationInfo();
        console.log('location', location);
        if (location != null) {
          signupdata.Latitude = location.latitude;
          signupdata.Longitude = location.longitude;
          signupdata.PinCode_Login = location.pincode;
          signupdata.Current_Location = location.locationName;
        }
      }
      setsignupdata((prevData) => ({
        ...prevData,
      }));
    }

    handleSignupData()
    
  }, []);

  const isButtonDisabled = !acceptTerms;

  function RegisterUser(iURl, iData) {
    axios
      .post(iURl, iData)
      .then((response) => {
        localStorage.setItem("HTES_user_id", signupdata.email);
        localStorage.setItem("HTES_user_name",  signupdata.name);
        //customAlert('',"Registered Successfully, Click Ok to Login page",'success')
        console.log('iData',iData)
        //setTimeout(() => {
          window.location.href = "/home";
        //}, 3000); // 3000 milliseconds = 3 seconds
       })
      .catch((error) => {
        console.log(error)
        if (error.response.status===400){
          localStorage.setItem("HTES_user_id", signupdata.email);
          localStorage.setItem("HTES_user_name",  signupdata.name);
          window.location.href = "/home";
          //customAlert('',error.response.data.error,'warning')
        }else{
          customAlert('','Something Went Wrong!','error')
        }
        
      });
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={handleGoogleLogin}
                  startIcon={<GoogleIcon />}
                >
                  Login With Google
                </Button>
                <br />
                <br />
                <Divider> or </Divider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  autoFocus
                  value={signupdata.name}
                  onChange={handleChange}
                  error={!!errors_V.name}
                  helperText={errors_V.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={signupdata.email}
                  onChange={handleChange}
                  error={!!errors_V.email}
                  helperText={errors_V.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="Phone Number"
                  name="mobile"
                  required
                  fullWidth
                  id="phonenumber"
                  label="Phone Number"
                  value={signupdata.mobile}
                  onChange={handleChange}
                  error={!!errors_V.mobile}
                  helperText={errors_V.mobile}
                />
              </Grid>
              {/* <Grid item xs={12}>
                
              <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor="outlined-userType">User Type</InputLabel>
      <Select
        label="User Type"
        id="outlined-userType"
        name="usertype"
        value={signupdata.usertype}
        required
        fullWidth
        onChange={handleChange}
        error={!!errors_V.usertype}
        helperText={errors_V.usertype}
      >
        <MenuItem value="" disabled>
          Select User Type
        </MenuItem>
        <MenuItem value="Admin">Admin</MenuItem>
        <MenuItem value="Teacher">Teacher</MenuItem>
        <MenuItem value="Student">Student</MenuItem>
        <MenuItem value="Parent">Parent</MenuItem>
      </Select>
    </FormControl>
              </Grid> */}
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    label="password"
                    name="password"
                    autoComplete="password"
                    value={signupdata.password}
                    onChange={handleChange}
                    error={!!errors_V.password}
                    helperText={errors_V.password}
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <>
                      <Checkbox
                        value="allowExtraEmails"
                        color="primary"
                        label="I accept all Term and Condition."
                        checked={acceptTerms}
                        onChange={handleCheckboxChange}
                      />
                      <Link color="inherit">
                        {" "}
                        I accept all Term and Condition.
                      </Link>
                    </>
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isButtonDisabled}
              sx={{ mt: 3, mb: 0 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignUp;
