import React from "react";
import { TextField, Button, Autocomplete } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import InputAdornment from "@mui/material/InputAdornment";
import { baseURL } from "../../http";
import axios from "axios";
import { customAlert } from "../SweetAlertCommon/Custom";


function StudentBasicDetailModal({
  togglingModal,
  pfname,
  setPfname,
  mobile,
  setMobile,
  expYear,
  setExpYear,
  expMonth,
  setExpmonth,
  noticePeriod,
  setNoticePeriod,
  location,
  setLocation,
  expSalary,
  setExpSalary,
  fetchUserData,
}) {
  //--------------------------------------------------------------------------------
  // State Handles
  //--------------------------------------------------------------------------------
  const userEmail = localStorage.getItem("HTES_user_id") || "";

  //--------------------------------------------------------------------------------
  //saveProfileData
  //--------------------------------------------------------------------------------

  async function saveProfileData() {
    try {
      const formData = {
        email: userEmail,
        name: pfname,
        expY: expYear ? expYear : null,
        expM: expMonth ? expMonth : null,
        phone: mobile,
        loc: location,
        notice: noticePeriod ? noticePeriod : null,
        curSal: expSalary,
      };
  
      const response = await axios.post(`${baseURL}/updateProfile`, formData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
  
      if (response.status === 200) {
        customAlert("", "Profile Data Updated", "success")
          fetchUserData();
          togglingModal();
        }
       else {
        console.error("Error updating profile1:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating profile2:", error);
    }
  }

  //--------------------------------------------------------------------------------
  // Dropdown Options
  //--------------------------------------------------------------------------------
  const noticePeriodOptions = [
    "15 Days or less",
    "1 Month",
    "2 Months",
    "3 Months",
  ];

  return (
    <>
      <div className="pmModalContainer">
        <div className="experience-container">
          <div className="experience-fields">
            {/* _______________________ Name Input _____________________ */}
            <div className="pmBasFields">
              <div className="formProfile">
                <TextField
                  label="Name"
                  fullWidth
                  value={pfname || ""}
                  onChange={(e) => setPfname(e.target.value)}
                  InputProps={{
                    className: "input_Custom_Style",
                  }}
                />
              </div>
              
            </div>
            {/* _______________________ Mobile Input ___________________ */}
            <div className="pmBasFields1">
              <TextField
                label="Mobile"
                fullWidth
                type="tel" // Using 'tel' to better indicate phone number input
                value={mobile || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "" || /^\d{0,10}$/.test(value)) {
                    setMobile(value);
                  }
                }}
                InputProps={{
                  className: "input_Custom_Style",
                }}
              />
            </div>
          </div>
        </div>
        {/* _______________________ Total Experience _______________ */}
        <div className="experience-container">
          <div className="experience-fields">
            <div className="pmBasFields">
              <TextField
                label="Total Experience in - Year(s)"
                fullWidth
                value={expYear || ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d*$/.test(inputValue)) {
                    setExpYear(inputValue);
                  }
                }}
                InputProps={{
                  className: "input_Custom_Style",
                  inputProps: {
                    style: { textAlign: "left" }, // Align text to the right
                  },
                  // endAdornment: (
                  //   <InputAdornment position="end">Year(s)</InputAdornment>
                  // ),
                  inputComponent: "input",
                }}
              />
            </div>

            <div className="pmBasFields1">
              <TextField
                label="Total Experience in - Month(s)"
                fullWidth
                value={expMonth || ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d*$/.test(inputValue) && inputValue <= 11) {
                    setExpmonth(inputValue);
                  }
                }}
                InputProps={{
                  className: "input_Custom_Style",
                  inputProps: {
                    style: { textAlign: "left" }, // Align text to the right
                  },
                  // endAdornment: (
                  //   <InputAdornment position="end">Month(s)</InputAdornment>
                  // ),
                  inputComponent: "input",
                }}
              />
            </div>
          </div>
        </div>

       
          {/* _______________________ Location ________________________ */}
         
            <div className="formProfile">
              <TextField
                label="Location"
                fullWidth
                value={location || ""}
                onChange={(e) => setLocation(e.target.value)}
                InputProps={{
                  className: "input_Custom_Style",
                }}
              />
            </div>
         

          {/* _______________________ Notice Period ___________________ */}
          <div>
            <Autocomplete // correct
              fullWidth
              options={noticePeriodOptions}
              getOptionLabel={(option) => option}
              value={noticePeriod}
              onChange={(event, newValue) => setNoticePeriod(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Notice Period"
                  value={noticePeriod || ""}
                  onChange={(e) => setNoticePeriod(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    className: "input_Custom_Style",
                  }}
                />
              )}
            />
          </div>
        
        {/* _______________________ Current Salary __________________ */}
        <div>
          <div>
            <div className="formProfile">
              <TextField
                label="Current Salary (LPA)"
                fullWidth
                value={expSalary || ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d*$/.test(inputValue)) {
                    setExpSalary(inputValue);
                  }
                }}
                InputProps={{
                  className: "input_Custom_Style", // Apply your custom CSS class here
                  inputProps: {
                    style: { textAlign: "left" }, // Example: Align text to the left
                  },
                  endAdornment: (
                    <InputAdornment position="end">Salary</InputAdornment>
                  ),
                  inputComponent: "input",
                }}
              />
            </div>
          </div>

          {/* _______________________ Save and Cancel ___________________ */}
        </div>
      </div>
      {/* _______________________       Save        _______________ */}
      <div className="createteslayoutmodel6">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={saveProfileData}
        >
         <SaveIcon className="buttonicons" />
          Save

        </Button>
      </div>
    </>
  );
}

export default StudentBasicDetailModal;
