import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faMapMarkerAlt, faExternalLinkAlt, faTools } from '@fortawesome/free-solid-svg-icons';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios
import { baseURL } from '../../http';
import './JobPortal.css';
import { customAlert } from '../SweetAlertCommon/Custom';
import Tooltip from '@mui/material/Tooltip';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';

const JobDetails = ({ job, showLaunchIcon = true, showCloseIcon = true, onClose }) => {
    const userEmail = localStorage.getItem("HTES_user_id") || "";
    const navigate = useNavigate();

    //----------------------------------------------------------------
    //Handle state
    //----------------------------------------------------------------
    const [profilePercentage, setProfilePercentage] = useState(0);
    const [isResumeUploaded, setIsResumeUploaded] = useState(false);
    const [companyProfile, setCompanyProfile] = useState("");
    const [applicantsCount, setApplicantsCount] = useState(0);
    const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(0);

    function formatLabels(input) {
        try {
            if (input) {
                const dataArray = JSON.parse(input);
                const labels = dataArray.map(item => item.label).join(', ');
                return labels;
            }
        } catch (error) {
            console.error('Invalid JSON input:', error);
        }
        return 'NA';
    }

    //----------------------------------------------------------------
    // Calculate the difference in days
    //----------------------------------------------------------------
    function calculateDaysAgo(dateString) {
        const postedDate = new Date(dateString);
        const currentDate = new Date();
        const timeDifference = currentDate - postedDate;
        const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return daysAgo;
    }

    //----------------------------------------------------------------
    // Fetch Profile and Company Data on Component Mount
    //----------------------------------------------------------------
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const profileResponse = await axios.get(`${baseURL}/getProfilePercentage/${userEmail}`);
                setProfilePercentage(profileResponse.data.profilePercentage || 0);

                const resumeResponse = await axios.get(`${baseURL}/checkResumeAvaialbility/${userEmail}`);
                setIsResumeUploaded(resumeResponse.data.message === 'available');
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };

        fetchProfileData();
    }, [userEmail]);

    useEffect(() => {
        const fetchApplicantsCount = async () => {
            try {
                const response = await axios.get(`${baseURL}/getApplicantsCount/${job.id}`);
                setApplicantsCount(response.data.count);
            } catch (error) {
                console.error('Error fetching applicants count:', error);
            }
        };

        fetchApplicantsCount();
    }, [job.id]);

    useEffect(() => {
        const fetchCompanyProfile = async () => {
            try {
                const companyResponse = await axios.get(`${baseURL}/companyProflie_fetch_all`);
                setCompanyProfile(companyResponse.data[selectedCompanyIndex].OverviewNormalContent || "Company information not available.");
            } catch (error) {
                console.error('Error fetching company profile data:', error);
                setCompanyProfile("Company information not available.");
            }
        };

        fetchCompanyProfile();
    }, [selectedCompanyIndex]); // Re-fetch when selected company changes

    //----------------------------------------------------------------
    // Handle Apply button click
    //----------------------------------------------------------------
    const handleApplyClick = async () => {
        const LoginEmail = localStorage.getItem("HTES_user_id");

        if (LoginEmail) {
            try {
                // Step 1: Fetch all applied jobs for the student
                const appliedJobsResponse = await axios.get(`${baseURL}/Apply_Job_fetch_all`);
                const appliedJobs = appliedJobsResponse.data;
                const studentAppliedJobs = appliedJobs.filter(job => job.StudentId === LoginEmail);
                console.log("Student Matching Email", studentAppliedJobs)
                const hasAlreadyApplied = studentAppliedJobs.some(appliedJob => appliedJob.JobId === String(job.id));
                console.log("Applied Job", hasAlreadyApplied)
                if (hasAlreadyApplied) {
                    customAlert("Warning", "You have already applied for this job.", "warning");
                    return;
                }
                // Step 2: Check profile completion and resume upload
                if (profilePercentage >= 60) {
                    if (isResumeUploaded) {
                        const applyJobData = {
                            StudentId: LoginEmail,
                            JobId: job.id,
                            ApplyDate: new Date().toISOString().split('T')[0],
                            Created_by: LoginEmail,
                            Modified_by: LoginEmail,
                            UTC_Time: new Date().toISOString(),
                        };
                        const response = await axios.post(`${baseURL}/Apply_Job`, applyJobData);
                        if (response.status === 200) {
                            customAlert("", `You have successfully applied for the ${job.Designation} position.`, "success");
                        }
                    } else {
                        customAlert("Warning", "Please upload your resume to apply for this job.", "warning");
                        navigate('/update_profile');
                    }
                } else {
                    customAlert("", "Please update your profile to at least 60% to apply for this job", "warning");
                    navigate('/update_profile');
                }
            } catch (error) {
                console.error('Error checking or applying for the job:', error);
                customAlert("Error", "Failed to apply for the job. Please try again later.", "error");
            }
        } else {
            navigate('/signin');
        }
    };

    return (
        <div className="job-details">

            {/*_________________ Designation and company name________________________*/}

            <div className="job-card-header">
                <div className="job-title-container">
                    <h3 className="job-title">{job.Designation}</h3>
                    {showLaunchIcon && (
                        <Tooltip title="Open in new page" arrow>
                            <Link to={`/job/${job.id}`} className="launch-icon">
                                <FontAwesomeIcon className="job-Link-icon" icon={faExternalLinkAlt} />
                            </Link>
                        </Tooltip>
                    )}
                    {showCloseIcon && (
                        <Tooltip title="Close" arrow>
                            <HighlightOffSharpIcon className="close-icon" onClick={onClose} />
                        </Tooltip>
                    )}
                </div>
                <span className="company-name">{job.CompanyName}</span>
            </div>

            {/*____________________Apply Button________________________*/}
            <button className="apply-job-button" onClick={handleApplyClick}>
                Apply
            </button>
            <br /> <br />
            <hr className='Question_FooterLine' />
            <div className="job-card-body">
                <div className="job-info-line">

                    {/*_____________________Experience___________________________*/}
                    <div className="job-info-item">
                        <FontAwesomeIcon icon={faBriefcase} className="job-icon" />
                        <span className="job-experience">{job.RequiredMinExperience}-{job.RequiredMaxExperience} years</span>
                    </div>
                    <div className="job-divider"></div>

                    {/*_______________________Salary____________________________*/}
                    <div className="job-info-item">
                        <CurrencyRupeeIcon className="rupee-icon" />
                        <span className="job-salary">{job.DesignationSalaryMin}-{job.DesignationSalaryMax} LPA</span>
                    </div>
                    <div className="job-divider"></div>

                    {/*________________________Location_________________________*/}
                    <div className="job-info-item">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="job-icon" />
                        <span className="job-location">{formatLabels(job.JobLocations)}</span>
                    </div>
                </div>

                {/*____________________________Skills_____________________________*/}
                <div className="job-info-item">
                    <FontAwesomeIcon icon={faTools} className="job-icon" />
                    <span className="job-skills">{formatLabels(job.RequiredKeyskills)}</span>
                </div> <br />

                {/*___________________Job posted date___________________________*/}
                <div className="job-opening-post">
                    <div className="job-posted-date">
                        <span>Posted: {calculateDaysAgo(job.ApplicationStartDate)} days ago</span>
                    </div>
                    <div className="job-divider"></div>
                    <div className="job-opening">
                        <span>Openings: {job.MaximumOpenings}</span>
                    </div>
                    <div className="job-divider"></div>
                    <div className="job-applicants">
                        <span>Applicants: {applicantsCount}</span>
                    </div>
                </div>
            </div>

            {/*__________________________Description_____________________________*/}
            <div className="job-additional-info">
                <div className="job-description">
                    <h3>Job Description: </h3>
                    <p>{job.Job_Description_NormalContent}</p>
                    <div className="job-detail-item">
                        <span className="job-label">Position: </span>
                        <span>{job.Designation}</span>
                    </div>
                    <div className="job-detail-item">
                        <span className="job-label">Location: </span>
                        <span>{formatLabels(job.JobLocations)}</span>
                    </div>
                    <div className="job-detail-item">
                        <span className="job-label">Roles & Responsibilities: </span>
                        <p>{job.JobResposibilities_NormalContent}</p>
                    </div>
                    <div className="job-detail-item">
                        <span className="job-label">Industry Type: </span>
                        <span>{formatLabels(job.IndustryType)}</span>
                    </div>
                    <div className="job-detail-item">
                        <span className="job-label">Employment Type: </span>
                        <span>{formatLabels(job.EmploymentType)}</span>
                    </div>
                    <div className="job-detail-item">
                        <span className="job-label">Education: </span>
                        <span>{formatLabels(job.RequiredEducation)}</span>
                    </div>
                    <div className="job-detail-item">
                        <span className="job-label">Key Skills: </span>
                        <span>{formatLabels(job.RequiredKeyskills)}</span>
                    </div>
                    <div className="job-detail-item">
                        <span className="job-label">Perks: </span>
                        <span>{job.perks_NormalContent}</span>
                    </div>
                </div>
            </div>

            {/* ________________________ About the company Section________________________*/}
            <div className="about-company">
                <h3>About the Company</h3>
                <p>{companyProfile}</p>
            </div>
        </div>
    );
};

export default JobDetails;
