import React, { useState } from "react";
import { IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import "./coursetemplate2.css";

const CourseTemplate2OverView = ({ title, content }) => {
  const [isExpand, setIsExpand] = useState(true);

  //--------------------------------------------------------------------------------
  // Expand and Hide the grid div
  //--------------------------------------------------------------------------------

  const handleExpand = () => {
    setIsExpand((prev) => !prev);
  };

  return (
    <>
      {/* ______________________________Heading and Buttons_____________ */}
      <div className="coursetemplate2-general-first-three-tabs">
        <div
          className={`coursetemplate2-title-and-expand-container-overall ${
            isExpand ? "" : "active"
          }`}
        >
          <div className="coursetemplate2-title-and-expand-container">
            <div
              className="coursetemplate2-title-expandicon"
              onClick={handleExpand}
            >
              <div className="coursetemplete2-title">{title}</div>
              {/* ____________________All icons and expan and collaps_____________ */}
              <IconButton>
                {isExpand ? (
                  <ExpandLessIcon className="course-template-expand-icon-color" />
                ) : (
                  <ExpandMoreIcon className="course-template-expand-icon-color" />
                )}
              </IconButton>
            </div>
          </div>
          {/* ____________________Contnet render area_____________ */}
          {isExpand && (
            <div className="coursetemplate2-content-render-conatiner">
              {content.map((point, index) => (
                <div
                  className="coursetemplate2-checkicon-and-points"
                  key={index}
                >
                  <div>
                    <CheckCircleOutlineIcon className="buttonicons1" />{" "}
                  </div>
                  &nbsp;
                  <div>{point}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CourseTemplate2OverView;
