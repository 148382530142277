import { useEffect, useState, useCallback } from "react";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import GoogleButton from "react-google-button";
import { baseURL } from "../../http";
import {
    getDeviceInfo,
    getCurrentDateTime,
    getLocationInfo,
    GoogleSignin,
    MsgErr_PreCfrm,
    MsgErr_NoCfrm,
    MsgSucces_PreCfrm,
    MsgSucess_NoCfrm,
} from "./Utilities_Function";
import GoogleIcon from "@mui/icons-material/Google";
import Divider from "@mui/material/Divider";
import { customAlert } from "../SweetAlertCommon/Custom";

const Swal = require("sweetalert2");

export default function ForgotPassword() {
    const HTES_user_id = localStorage.getItem("HTES_user_id");

    // Get Device info
    const [LoginData, setLoginData] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
    status: "",
    last_Login_time: null,
    last_login_device_name: null,
    error: "",
    Longitude: null,
    Latitude: null,
    PinCode_Login: null,
    Current_Location: null,
    });

    // Check autofill callback
    const checkAutofill = useCallback(() => {
        if (LoginData.email.trim() !== "") {
            console.log("Email autofilled:", LoginData.email);
        }
    }, [LoginData.email]);

    // Change Element Value
    const handleChange = (event) => {
        setLoginData({
            ...LoginData,
            [event.target.name]: event.target.value,
        });
        checkAutofill();
    };

    // State for password visibility
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Handle login function
    function loginHandle(iUrl, iData) {
        axios
            .post(iUrl, iData)
            .then((response) => {
                if (response.data.status == true) {
                    customAlert('', "Password Updated Successfully","success");
                    window.location.href = "/signin";                    
                } 
                
            })
            .catch((error) => {
                if (error.response.status===411) {
                    customAlert('','User Not Found','warning')
                }else if(error.response.status===412){
                    customAlert('','This user is blocked. Please contact the administrator','warning')
                }else if(error.response.status===413){
                    customAlert('','The password field must be at least 8 characters','warning')
                }else{
                    customAlert('','something went Wrong','error')    
                }
            });
    }

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();

        // Check if any of the fields are empty
        if (!LoginData.email || !LoginData.password || !LoginData.confirmPassword) {
            customAlert("", "Please fill all the fields", "warning");
            return;
        }

        // Check if entered email is in a valid format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(LoginData.email)) {
            customAlert("", "Please enter a valid email address", "warning");
            return;
        }

        // Check if passwords match
        if (LoginData.password !== LoginData.confirmPassword) {
            customAlert("", "New and Confirm Passwords do not match", "warning");
            return;
        }

        // Call loginHandle function
         loginHandle(baseURL + "/forgotpassword", LoginData);
    };


 
    // Component Did Mount effect
    useEffect(() => {
        document.title = "Forgot Password";
        document.title = "SignUp";
        if (LoginData.last_login_device_name == null) {
            LoginData.last_login_device_name  = getDeviceInfo();
        }
      
        if (LoginData.last_Login_time == null) {
            LoginData.last_Login_time  = getCurrentDateTime();
        }
    
        async function handleSignupData() {
          if (LoginData.Longitude == null) {
            const location = await getLocationInfo();
            console.log('location', location);
            if (location != null) {
                LoginData.Latitude = location.latitude;
                LoginData.Longitude = location.longitude;
                LoginData.PinCode_Login = location.pincode;
                LoginData.Current_Location = location.locationName;
            }
          }
          setLoginData((prevData) => ({
            ...prevData,
          }));
        }
    
        handleSignupData()
        
      }, []);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Forgot Password
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
                    <Grid container spacing={1.5}>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                id="email"
                                label="Enter Registered Email"
                                name="email"
                                autoComplete="email"
                                onChange={handleChange}
                                autoFocus
                                value={LoginData.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="New Password"
                                name="password"
                                fullWidth
                                required
                                onChange={handleChange}
                                value={LoginData.password}
                                id="outlined-adornment-password"
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                label="Confirm Password"
                                name="confirmPassword"
                                onChange={handleChange}
                                id="confirm-password"
                                type="password"
                                value={LoginData.confirmPassword}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Update Password
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}
