import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";

function FooterComponent3() {
  return (
    /*_______________________________________Footer content 3rd component_____________________________*/
    <div className="footer_content">
      {/*_______________________________________Footer content 3rd component heading_____________________________*/}
      <div className="footer-1st-paragraph-component-header">
        <div className="footer-heading">Follow Us</div>
      </div>
      {/*_______________________________________Footer content 3rd component list_____________________________*/}
      <div className="footer-component3-alignmet-conatiner-flex">
        <div className="footer-1st-paragraph-component3">
          <a href="https://m.facebook.com/100082084068949/">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </div>
        <div className="footer-1st-paragraph-component3">
          <a href="https://www.linkedin.com/in/talent-pool-academy?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
        <div className="footer-1st-paragraph-component3">
          <a href="https://www.instagram.com/talent_pool_academy09/?utm_source=ig_web_button_share_sheet">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
        {/* _______________________________________end______________________________________ */}
      </div>
    </div>
  );
}

export default FooterComponent3;
