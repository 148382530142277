import React, { useEffect, useRef } from "react";
import "./HireTalent.css";
import { FaAward, FaUsers, FaChalkboardTeacher } from "react-icons/fa";

const UniqueSellingPoints = () => {
  const uspSectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    if (uspSectionRef.current) {
      observer.observe(uspSectionRef.current);
    }

    return () => {
      if (uspSectionRef.current) {
        observer.unobserve(uspSectionRef.current);
      }
    };
  }, []);

  return (
    <div className="usp-main">
      <div className="usp-section" ref={uspSectionRef}>
        <h2>What Sets Us Apart</h2>
        <div className="usp-cards">
          <div className="usp-card">
            <FaAward className="usp-icon" />
            <h3>Expert Trainers</h3>
            <p>Industry experts with years of experience.</p>
          </div>
          <div className="usp-card">
            <FaUsers className="usp-icon" />
            <h3>Customized Solutions</h3>
            <p>Tailored training for your needs.</p>
          </div>
          <div className="usp-card">
            <FaChalkboardTeacher className="usp-icon" />
            <h3>Proven Track Record</h3>
            <p>Numerous satisfied clients.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniqueSellingPoints;
