import React, { useState } from "react";
import Select from "@mui/material/Select";
import SaveIcon from "@mui/icons-material/Save";
import { TextField, Button, FormControl, FormHelperText } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import UpdateIcon from "@mui/icons-material/Update";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

function Stscroll8ProjectsAddModal({
  togglingModal,
  fetchProjects,
  projectData,
  setProjectData,
  empList,
  callPercentage,
}) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  const [showMore, setShowMore] = useState("no");
  const [invalidFields, setInvalidFields] = useState({});

  //--------------------------------------------------------------------------------
  // Drop down options
  //--------------------------------------------------------------------------------

  const projectOptions = empList.map((companyName) => ({
    value: companyName, // Use an appropriate value for each option
    label: companyName, // Use the company name as the label
  }));

  //--------------------------------------------------------------------------------
  // Save Data API
  //--------------------------------------------------------------------------------
  const addProjectDetails = () => {
    const api =
      projectData.id !== 0
        ? `updateStudentProject/${projectData.id}`
        : "createStudentProject";

    // Fields to validate
    const requiredFields = ["projectTitle"];

    // Validate required fields
    const newInvalidFields = {};
    for (const field of requiredFields) {
      console.log(!projectData[field]);

      if (!projectData[field]) {
        newInvalidFields[field] = true;
      }
    }
    console.log(Object.keys(newInvalidFields).length);
    if (Object.keys(newInvalidFields).length > 0) {
      setInvalidFields(newInvalidFields);
      return;
    }
    setInvalidFields({});

    fetch(`${baseURL}/${api}`, {
      method: "POST",
      body: JSON.stringify(projectData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          customAlert(
            "",
            projectData.id !== 0 ? "Project Edited" : "Project Added",
            "success"
          );
          togglingModal(0);
          fetchProjects();
          callPercentage();
        } else {
          console.error("inside API error ");
        }
      })
      .catch((error) => {
        console.error("API call error-outside:", error);
      });
  };
  return (
    <>
      <div className="pmModalContainer-project ">
        {/* ___________________________________ Tab Heading _______________________________ */}
        {/* ___________________________________  Project title ____________________________ */}
        <div className="pmLabel-and-container-gap">
          <span className="pmLabel">Project title</span>

          <TextField
            fullWidth
            value={projectData.projectTitle || ""}
            onChange={(e) =>
              setProjectData({ ...projectData, projectTitle: e.target.value })
            }
            InputProps={{
              className: "input_Custom_Style",
            }}
            error={invalidFields.projectTitle}
            helperText={invalidFields.projectTitle ? "* mandatory" : ""}
          />
        </div>

        {/* ___________________________________  Project Tag   ____________________________ */}
        <div className="pmLabel-and-container-gap">
          <span className="pmLabel">
            Tag this Project with Employment/Education
          </span>

          <FormControl fullWidth error={invalidFields.projectTag}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={projectData.projectTag}
              onChange={(e) =>
                setProjectData({ ...projectData, projectTag: e.target.value })
              }
              className="input_Custom_Style"
            >
              {projectOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {invalidFields.projectTag && (
              <FormHelperText>* mandatory</FormHelperText>
            )}
          </FormControl>
        </div>

        {/* ___________________________________  Client       ____________________________ */}
        <div className="pmLabel-and-container-gap">
          <span className="pmLabel">Client</span>

          <TextField
            fullWidth
            value={projectData.client || ""}
            onChange={(e) =>
              setProjectData({ ...projectData, client: e.target.value })
            }
            InputProps={{
              className: "input_Custom_Style",
            }}
            error={invalidFields.client}
            helperText={invalidFields.client ? "* mandatory" : ""}
          />
        </div>

        {/* ___________________________________  Project Status (Radio) ___________________ */}
        <div className="pmLabel-and-container-gap">
          <span
            className={`pmLabel ${
              invalidFields.projectStatus ? "error_Pmlabel" : ""
            }`}
          >
            Project Status
          </span>

          <div className="PMradio_group">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={projectData.projectStatus}
              onChange={(e) =>
                setProjectData({
                  ...projectData,
                  projectStatus: e.target.value,
                })
              }
              className="PmRadioView"
            >
              <FormControlLabel
                value="In-Progress"
                control={<Radio />}
                label="In-Progress"
                className="PmRadioItem"
              />
              <FormControlLabel
                value="Finished"
                control={<Radio />}
                label="Finished"
                className="PmRadioItem"
              />
            </RadioGroup>
          </div>
        </div>

        {/* ___________________________________  Worked From and Till  ___________________________ */}

        <div className="experience-container">
          <div className="experience-fields">
            <div className="pmBasFields">
              <span className="pmLabel">Worked From</span>
              <div className="pmLabel-data-alignment">
                <TextField
                  required
                  type="date"
                  fullWidth
                  value={projectData.projectFrom}
                  onChange={(e) => {
                    setProjectData({
                      ...projectData,
                      projectFrom: e.target.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    className: "input_Custom_Style",
                    inputProps: {
                      style: { textTransform: "uppercase" },
                      max: projectData.projectTill,
                    },
                  }}
                  error={invalidFields.projectFrom}
                  helperText={invalidFields.projectFrom ? "* mandatory" : ""}
                />
              </div>
            </div>
            <div className="pmBasFields">
              <label className="pmLabel">Worked Till</label>
              <div className="pmLabel-data-alignment">
                <TextField
                  disabled={
                    projectData.projectStatus === "In-Progress" &&
                    projectData.projectStatus !== "Finished"
                  }
                  required
                  type="date"
                  fullWidth
                  value={projectData.projectTill}
                  onChange={(e) => {
                    setProjectData({
                      ...projectData,
                      projectTill: e.target.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    className: "input_Custom_Style",
                    inputProps: {
                      style: { textTransform: "uppercase" },
                      min: projectData.projectFrom,
                      max: new Date().toISOString().split("T")[0], // Disables future dates
                    },
                  }}
                  error={invalidFields.projectTill}
                  helperText={invalidFields.projectTill ? "* mandatory" : ""}
                />
              </div>
            </div>
          </div>
        </div>

        {projectData.projectStatus === "Finished" ? <></> : null}

        {/* _______________________________ Details of Project   _________________________ */}
        <div className="pmLabel-and-container-gap">
          <label className="pmLabel">Details of Project</label>

          <TextField
            fullWidth
            id="outlined-multiline-static"
            multiline
            minRows={2}
            // rows={4}
            value={projectData.projectdetails || ""}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                projectdetails: e.target.value,
              })
            }
            InputProps={{
              className: "input_Custom_Style",
            }}
            error={invalidFields.projectdetails}
            helperText={invalidFields.projectdetails ? "* mandatory" : ""}
          />
        </div>

        {/* _______________________________     Show More        _________________________ */}
        {showMore !== "yes" ? (
          <>
            <div>
              <label
                className="pmLabel pmShowMoreColor"
                onClick={(e) => setShowMore("yes")}
              >
                Add More Details
              </label>
            </div>
          </>
        ) : null}
        {showMore === "yes" ? (
          <>
            {/* _____________________     Project Location       _______________ */}
            <div className="pmLabel-and-container-gap">
              <label className="pmLabel">Project Location</label>

              <TextField
                fullWidth
                value={projectData.projectLocation || ""}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    projectLocation: e.target.value,
                  })
                }
                InputProps={{
                  className: "input_Custom_Style",
                }}
              />
            </div>

            {/* _____________________     Project Site       _______________ */}
            <div className="pmLabel-and-container-gap">
              <label className="pmLabel">Project Site</label>

              <div className="PMradio_group">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={projectData.projectSite}
                  onChange={(e) =>
                    setProjectData({
                      ...projectData,
                      projectSite: e.target.value,
                    })
                  }
                  className="PmRadioView"
                >
                  <FormControlLabel
                    value="Offsite"
                    control={<Radio />}
                    label="Offsite"
                    className="PmRadioItem"
                  />
                  <FormControlLabel
                    value="Onsite"
                    control={<Radio />}
                    label="Onsite"
                    className="PmRadioItem"
                  />
                </RadioGroup>
              </div>
            </div>

            {/* ____________________ Nature of the Employment  _____________ */}
            <div className="pmLabel-and-container-gap">
              <label className="pmLabel">Nature of the Employment</label>

              <div className="PMradio_group">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={projectData.empNature}
                  onChange={(e) =>
                    setProjectData({
                      ...projectData,
                      empNature: e.target.value,
                    })
                  }
                  className="PmRadioView"
                >
                  <FormControlLabel
                    value="Full time"
                    control={<Radio />}
                    label="Full time"
                    className="PmRadioItem"
                  />
                  <FormControlLabel
                    value="Part time"
                    control={<Radio />}
                    label="Part time"
                    className="PmRadioItem"
                  />
                  <FormControlLabel
                    value="Contractual"
                    control={<Radio />}
                    label="Contractual"
                    className="PmRadioItem"
                  />
                </RadioGroup>
              </div>
            </div>

            {/* _______________________     Team Size      _________________ */}
            <div className="pmLabel-and-container-gap">
              <label className="pmLabel">Team Size</label>

              <TextField
                fullWidth
                value={projectData.teamSize || ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d*$/.test(inputValue)) {
                    setProjectData({
                      ...projectData,
                      teamSize: inputValue,
                    });
                  }
                }}
                InputProps={{
                  className: "input_Custom_Style",
                  inputProps: {
                    style: { textAlign: "left" }, // Align text to the left
                  },
                }}
              />
            </div>

            {/* _______________________   Role Description _________________ */}
            <div className="pmLabel-and-container-gap">
              <label className="pmLabel">Role Description</label>

              <TextField
                fullWidth
                multiline
                // rows={3}
                minRows={3}
                value={projectData.roleDescription || ""}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    roleDescription: e.target.value,
                  })
                }
                InputProps={{
                  className: "input_Custom_Style",
                }}
              />
            </div>
          </>
        ) : null}
      </div>
      {/* _____________________________     Save  &  Cancel   _______________________ */}
      <div className="createteslayoutmodel6-resume-headline">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={addProjectDetails}
        >
          {projectData.id !== 0 ? (
            <>
              <UpdateIcon className="buttonicons" /> Update
            </>
          ) : (
            <>
              <SaveIcon className="buttonicons" /> Save
            </>
          )}
        </Button>
      </div>
    </>
  );
}

export default Stscroll8ProjectsAddModal;
