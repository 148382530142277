import React, { useEffect, useState } from "react";
import "./Cards.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../http";

const Cards = ({ showLimited, searchTerm, categoryTerm, publishedDateTerm }) => {
  const [posts, setPosts] = useState([]);

  // Helper function to calculate how many days ago the job was posted
  const getDaysAgo = (jobDate) => {
    const jobDateObj = new Date(jobDate); // Convert string to Date object
    const currentDate = new Date(); // Current date
    const timeDifference = currentDate - jobDateObj;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference === 0 ? "Today" : `${daysDifference} day${daysDifference > 1 ? "s" : ""} ago`;
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${baseURL}/blogs_getall`);

        // Parse Category and filter out unapproved posts
        const parsedData = response.data
          .filter((post) => post.IsApproved === 1) // Only approved posts
          .map((post) => ({
            ...post,
            Category: JSON.parse(post.Category), // Parse Category field
            Created_date: new Date(post.Created_date) // Ensure Created_date is a Date object
          }));

        // Sort posts by date, newest first
        const sortedPosts = parsedData.sort(
          (a, b) => b.Created_date - a.Created_date
        );

        setPosts(sortedPosts);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  // Filter and sort posts based on search term, category term, and published date term
  const filteredPosts = posts
    .filter((post) => {
      const search = searchTerm ? searchTerm.toLowerCase() : "";
      const category = categoryTerm ? categoryTerm.toLowerCase() : "";
      const daysAgo = getDaysAgo(post.Created_date);

      const headingMatch =
        post.heading &&
        typeof post.heading === "string" &&
        post.heading.toLowerCase().includes(search);
      const contentMatch =
        post.normalContent &&
        typeof post.normalContent === "string" &&
        post.normalContent.toLowerCase().includes(search);

      const categoryMatch = Array.isArray(post.Category)
        ? post.Category.some(
            (cat) =>
              typeof cat === "string" && cat.toLowerCase().includes(category)
          )
        : typeof post.Category === "object" &&
          Object.values(post.Category).some(
            (value) =>
              typeof value === "string" && value.toLowerCase().includes(category)
          );

      const publishedDateMatch = (publishedDateTerm) => {
        const daysAgo = getDaysAgo(post.Created_date);

        if (!publishedDateTerm) return true;

        // Handle predefined filters like "today", "1 week ago", etc.
        switch (publishedDateTerm.toLowerCase()) {
          case "today":
            return daysAgo === "Today";
          case "1 day ago":
            return daysAgo === "1 day ago";
          case "1 week ago":
            return daysAgo.includes("7") || daysAgo.includes("Today");
          case "1 month ago":
            return daysAgo.includes("30") || daysAgo.includes("Today");
          case "3 months ago":
            return daysAgo.includes("90") || daysAgo.includes("Today");
          case "6 months ago":
            return daysAgo.includes("180") || daysAgo.includes("Today");
          case "1 year ago":
            return daysAgo.includes("365") || daysAgo.includes("Today");
          case "all":
            return true;
          default:
            const match = publishedDateTerm.match(/(\d+)\s*days\s*ago/i);
            if (match) {
              const days = parseInt(match[1], 10); // Extract the number of days
              return daysAgo === `${days} day${days > 1 ? "s" : ""} ago`; // Return true if the days match
            }
            return true;
        }
      };

      return (
        (headingMatch || contentMatch) &&
        (categoryMatch || !categoryTerm) &&
        publishedDateMatch(publishedDateTerm)
      );
    })
    .sort((a, b) => b.Created_date - a.Created_date); // Sort by date, newest first

  const postsToShow = showLimited ? filteredPosts.slice(0, 6) : filteredPosts;

  // Check if filters are applied
  const isFilterApplied =
    searchTerm || categoryTerm || publishedDateTerm;

  return (
    <div className="latest-posts">
      {isFilterApplied && postsToShow.length === 0 ? (
        <p>No posts found for the selected filters.</p>
      ) : (
        postsToShow.map((post) => (
          <div className="post-card" key={post.id}>
            <div className="post-content">
              <p className="post-category">
                {Array.isArray(post.Category)
                  ? post.Category.join(", ")
                  : post.Category}
              </p>
              <h3 className="post-title">{post.heading}</h3>
              <p className="post-description">
                {post.normalContent && post.normalContent.length > 100
                  ? post.normalContent.substring(0, 100) + "..."
                  : post.normalContent}
              </p>
              <hr />
              <div className="post-meta">
                <span className="created-by">Created by : {post.Created_by}</span>
                <span className="published-date">
  Published: {getDaysAgo(post.Created_date)}
</span>
              </div>
              <Link to={`/post/${post.id}`} className="post-link">
                READ MORE <ArrowForwardIcon className="arrow-icon" />
              </Link>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Cards;
