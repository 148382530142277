import React, { useState, useEffect } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Tooltip from '@mui/material/Tooltip';
import './WhatsappButton.css';

function WhatsappButton() {
  const [showButton, setShowButton] = useState(false); // State to track button visibility

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 50) { // Show the button after 200px of scrolling
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }

    window.addEventListener('scroll', handleScroll); // Add scroll event listener
    return () => {
      window.removeEventListener('scroll', handleScroll); // Clean up event listener
    };
  }, []);

  const phoneNumber = "7483868010"; // Default phone number
  const message = "Hi, I need help with your services!"; // Default message
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <>
      {showButton && (
        <div className="whatsapp-button-container">
          <Tooltip title="WhatsApp" arrow placement='left'>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              <WhatsAppIcon className="whatsapp-icon" />
            </a>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default WhatsappButton;
