import React, { useState } from "react";
import "./interviewquestion.css"; // Import your CSS file for styling

function InterViewQuestionCard({ question = {} }) {
  const [isExpanded, setIsExpanded] = useState(false);

  // Utility function to format the date in DD/MM/YYYY format
  const formatDate = (interviewDate) => {
    const interviewDateObj = new Date(interviewDate);
    const day = String(interviewDateObj.getDate()).padStart(2, "0");
    const month = String(interviewDateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = interviewDateObj.getFullYear();

    return `${day}/${month}/${year}`;
  };

  // Parsing skills array
  const parseSkills = (skillsString) => {
    try {
      const cleanString = skillsString
        .replace(/^\[|\]$/g, "")
        .replace(/'/g, '"');
      const skillsArray = JSON.parse(`[${cleanString}]`);
      return skillsArray;
    } catch (error) {
      console.error("Error parsing skills string:", error);
      return [];
    }
  };

  const skillsArray =
    typeof question.Skills === "string" ? parseSkills(question.Skills) : [];
  const skillsString = skillsArray.map((skill) => skill.trim()).join(", ");

  // Toggle the card content expand/collapse
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Clean code snippet content
  const cleanCodeSnippet = (codeSnippet) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = codeSnippet;

    const editButtons = tempDiv.querySelectorAll(".edit-button-editor2");
    editButtons.forEach((button) => {
      button.remove();
    });

    return tempDiv.innerHTML;
  };

  return (
    <div
      className={`interview-questions-card ${
        isExpanded ? "expanded" : "collapsed"
      }`}
    >
      <div className="interview-questions-container">
        <h3 className="interview-questions-question">
          Q.&nbsp;{question.Question || ""}
        </h3>
        <div className="interview-questions-separator"></div>
        <p className="interview-questions-info">
          <strong>Company:</strong> {question.Company || "N/A"} |{" "}
          <strong>Role:</strong> {question.Designation || "N/A"} |{" "}
          <strong>Date:</strong>{" "}
          {question.InterviewDate ? formatDate(question.InterviewDate) : "N/A"}
        </p>
        <div className="interview-questions-answer-section">
          <p
            className={`interview-questions-answer ${
              isExpanded
                ? "interview-questions-expanded"
                : "interview-questions-collapsed"
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: question.Answer || "" }} />
          </p>
          <span
            className="interview-questions-toggle-text"
            onClick={toggleExpand}
          >
            {isExpanded ? "Read Less" : "Read More"}
          </span>
          {/* Display the skills only when the answer section is expanded */}
          {isExpanded && (
            <p className="interview-questions-skills">
              <strong>Skills Required:</strong> {skillsString || "N/A"}
            </p>
          )}
        </div>

        {/* Render the code snippet if available */}
        {question.CodeSnippet && (
          <div className="interview-answer-codesnap-container">
            <pre>
              <code
                dangerouslySetInnerHTML={{
                  __html: cleanCodeSnippet(question.CodeSnippet),
                }}
              />
            </pre>
          </div>
        )}
      </div>
    </div>
  );
}

export default InterViewQuestionCard;
