import React from "react";
import ReviewImage from "./ReviewImage/feedback.png";
import "./reviewsection.css";

function ReviewHeaderSection() {
  return (
    <div className="review-section-header-backgroundcolor">
      <div className="review-section-width-container">
        {/* _____________Heading and content section____________ */}
        <div className="review-section-flex-container">
          <div className="review-section-content-content-section">
            <h1 className="section-title">What Our Customers Are Saying</h1>
            <div className="review-content-paragraph-section">
              Your feedback is the heartbeat of our journey. Dive into the
              stories shared by others and let your voice be heard. Every
              experience adds to our collective story
            </div>
          </div>
          {/* ___________________________end______________________ */}
          {/* _________________________Image section______________ */}
          <div className="review-section-image-container">
            <img src={ReviewImage} alt="Review_image" />
          </div>
          {/* _______________________end__________________________ */}
        </div>
      </div>
    </div>
  );
}

export default ReviewHeaderSection;
