import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import axios from "axios";
import { baseURL } from "../../http";
import Stscroll8ProjectsAddModal from "./PmTab8ProjectsAddModal";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { customAlert } from "../SweetAlertCommon/Custom";

function Stscroll8Projects({ callPercentage }) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  const [empList, setEmpList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const userEmail = localStorage.getItem("HTES_user_id") || "";
  const [projectData, setProjectData] = useState({
    email: userEmail,
    id: 0,
    projectTitle: "",
    client: "",
    projectStatus: "",
    projectTag: "",
    projectFrom: "", // worked From
    projectTill: "", // worked Till
    projectdetails: "",
    projectLocation: "",
    projectSite: "",
    empNature: "",
    teamSize: "",
    roleDescription: "",
  });

  const [modalStatus, setModalStatus] = useState(false);

  //--------------------------------------------------------------------------------
  //  Modal toggles
  //--------------------------------------------------------------------------------
  const toggleModal = (itemID) => {
    setModalStatus(!modalStatus);

    const selectedItem = projectList.find((item) => item.id === itemID);
    setProjectData({
      email: userEmail,
      id: itemID,
      projectTitle: itemID !== 0 ? selectedItem.projectTitle : "",
      projectTag: itemID !== 0 ? selectedItem.projectTag : "",
      client: itemID !== 0 ? selectedItem.client : "",
      projectStatus: itemID !== 0 ? selectedItem.projectStatus : "",
      projectFrom: itemID !== 0 ? selectedItem.projectFrom : "", // added New
      projectTill: itemID !== 0 ? selectedItem.projectTill : "", // added New
      projectdetails: itemID !== 0 ? selectedItem.projectdetails : "",
      projectLocation: itemID !== 0 ? selectedItem.projectLocation : "",
      projectSite: itemID !== 0 ? selectedItem.projectSite : "",
      empNature: itemID !== 0 ? selectedItem.empNature : "",
      teamSize: itemID !== 0 ? selectedItem.teamSize : "",
      roleDescription: itemID !== 0 ? selectedItem.roleDescription : "",
    });
  };

  //--------------------------------------------------------------------------------
  // Fetching the Data from API
  //--------------------------------------------------------------------------------
  const fetchStudentProjectList = () => {
    const requestBody = {
      email: userEmail,
    };

    axios
      .post(`${baseURL}/getAllProjects`, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setProjectList(response.data || []);
      })
      .catch((error) => {
        console.error("Error fetching Projects_details:", error);
      });
  };

  useEffect(() => {
    fetchStudentProjectList();
    fetchStudentEmpList();
  }, [modalStatus]);

  const fetchStudentEmpList = () => {
    const requestBody = {
      email: userEmail,
    };

    axios
      .post(`${baseURL}/getAllStudentEmployment`, requestBody)
      .then((response) => {
        const data = response.data;
        const companyNames = data.map((item) => item.currentCompanyname);
        setEmpList(companyNames || []);
      })
      .catch((error) => {
        console.error("Error fetching Emp_details:", error);
      });
  };

  //--------------------------------------------------------------------------------
  // API - Delete IT skill Item
  //--------------------------------------------------------------------------------
  const handleItemDelete = (itemId) => {
    const updatedEmpList = empList.filter((item) => item.id !== itemId);
    setEmpList(updatedEmpList);

    axios
      .put(`${baseURL}/deleteStudentProject/${itemId}`, null, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          customAlert("", "Project Deleted", "success");
          fetchStudentProjectList();
          callPercentage();
        } else {
          customAlert("", "API- Error", "warning");
          console.error("Failed to delete item");
        }
      })
      .catch((error) => {
        customAlert("", "Server Down !", "warning");
        console.error("Item deleting user:", error);
      });
  };

  //--------------------------------------------------------------------------------
  // Helper function to format date
  //--------------------------------------------------------------------------------
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };
  return (
    <div>
      {/* ___________________________________ Tab Heading _______________________________ */}

      <div className="pmEmpOuterContainer">
        <span className="pmTabHeadings2"> Projects </span>
        <span onClick={() => toggleModal(0)} className="pmAddEmpBtn">
          Add Projects
        </span>
      </div>

      {/* ___________________________________ Project Data Render Map ____________________ */}
      <div>
        {projectList.map((data) => (
          <div key={data.id}>
            {/* ________________ Project Title ________________ */}
            <span className="pmRemderListTitle">{data.projectTitle}</span>{" "}
            <Tooltip title="Edit" arrow>
              <IconButton onClick={() => toggleModal(data.id)}>
                <EditIcon className="pmEditIcon" />
              </IconButton>
            </Tooltip>
            <br />
            {/* ________________ Project Client ________________ */}
            <span className="pmEmpCompanyName">{data.client}</span>
            <br />
            {/* _____ Project Duration (Start and End Date) _____ */}
            <div className="pmDimDuration pmProlifeUserData">
              {formatDate(data.projectFrom)} to{" "}
              {data.projectStatus == "In-Progress"
                ? "In-Progress"
                : formatDate(data.projectTill)}
            </div>
            {/* ________________ Project Details ________________ */}
            <div className="pmProlifeUserData">{data.projectdetails}</div>
            <hr />
          </div>
        ))}
      </div>

      {/* ___________________________________ Add Modal code starts   ____________________ */}
      <Modal
        open={modalStatus}
        onClose={setModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pmMuiModalBox">
          <div className="pmresume-heading-container">
            {/* ________________ Heading ________________ */}
            <span className="pmTabHeadings2"> Projects</span>
            <IconButton
              id="button-zoom"
              className="modal-close-iconButton-right-top-corner"
              onClick={() => setModalStatus(false)}
            >
              <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
            </IconButton>
          </div>
          {/* _____________ Delete Button _____________ */}
          {projectData.id > 0 && (
            <div className="deleteIconAtpmModal">
              <Tooltip title="Delete">
                <IconButton
                  variant="contained"
                  color="error"
                  onClick={() => handleItemDelete(projectData.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}

          {/* _____________ Component call _____________ */}
          <Stscroll8ProjectsAddModal
            togglingModal={toggleModal}
            fetchProjects={fetchStudentProjectList}
            projectData={projectData}
            setProjectData={setProjectData}
            empList={empList}
            callPercentage={callPercentage}
          />
        </div>
      </Modal>
      {/* ___________________________________ Add_Modal code Ends   ____________________________ */}
    </div>
  );
}

export default Stscroll8Projects;
