import React, { useState, useEffect } from "react";
import HeaderMain from "../Header/HeaderMain";
import HireTalentHero from "./HireTalentHero";
import HireTalentMission from "./HireTalentMission";
import HireBenifits from "./HireBenifits";
import UniqueSellingPoints from "./UniqueSellingPoints";
import Teach3InstructorFAQ from "../TeachUSonTPA/Teach3InstructorFAQ";
import BackToTop from "../BacktoTop/BackToTop";
import FooterMain from "../Footer/FooterMain";
import PageLoading from "../PageLoading/PageLoading";
import HeaderSmallBar from "../Home/HeaderTopSmallBar/HeaderSmallBar";
import { baseURL } from '../../http'
import axios from 'axios';
import WhatsappButton from "../Whatsapp/WhatsappButton";


function HireTalentMain() {
  const [isLoading, setIsLoading] = useState(true);
  const [faqData, setFaqData] = useState([]);

  //-------------------------------------------------------------------------
  //Page Loading and FAQ data from backend
  //-------------------------------------------------------------------------
  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await axios.get(`${baseURL}/FAQ_fetch_all`);
        const data = response.data;
  
        const approvedFaqs = data
          .filter((faq) => faq.IsApproved === "1")
          .filter((faq) => faq.Category.includes("Hire Talent"));
  
        const mappedFaqData = approvedFaqs.map((faq) => ({
          question: faq.Question,
          answer: faq.Answer,
        }));
  
        console.log("Mapped FAQ Data:", mappedFaqData);
        setFaqData(mappedFaqData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching FAQ data:", error);
        setIsLoading(false);
      }
    };
  
    fetchFAQs();
  }, []);

  return (
    <div>
      {isLoading ? (
        <PageLoading image="TalentPoolAcademy_logo.png" />
      ) : (
        <div>
          <HeaderSmallBar/>
          <HeaderMain />
          <HireTalentHero />
          <HireTalentMission />
          <HireBenifits />
          <UniqueSellingPoints />
          <Teach3InstructorFAQ faqData={faqData} headerName={"Client FAQs"} />
          <FooterMain />
          <WhatsappButton />
          <BackToTop/>
        </div>
      )}
    </div>
  );
}

export default HireTalentMain;
