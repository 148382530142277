import React, { useState } from "react";
import "./interviewquestion.css";
import questionadd from "./InterViewQuestionImages/mistery-box-concept-illustration.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import InterviewQuestionGenaralInfoAddModal from "./InterviewQuestionGenaralInfoAddModal";
import { customAlert } from "../SweetAlertCommon/Custom";

function InterviewQuestionAddQuestion() {
  const [open, setOpen] = useState(false);
  const LoginEmail = localStorage.getItem("HTES_user_name");

  //-------------------------------------------------------------------------
  // Before add the question need to login (alert before modal open)
  //-------------------------------------------------------------------------
  const handleOpenModal = () => {
    if (LoginEmail) {
      setOpen(true);
    } else {
      customAlert(
        "",
        "Please Login To Add Your InterView Questions",
        "warning"
      );
    }
  };

  //-------------------------------------------------------------------------
  // Close the modal
  //-------------------------------------------------------------------------
  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <div className="interviewquestion-section-add-interview-backgroundcolor">
      <div className="interviewquestion-section-add-interview-width-container">
        <div className="interview-question-render-section-heading">
        Share Your Interview Experience
        </div>
        {/* _____________Heading and content section____________ */}
        <div className="interviewquestion-section-add-interview-flex-container">
          {/* ___________________________end______________________ */}
          {/* _________________________Image section______________ */}
          <div className="interviewquestion-section-add-interview-image-container">
            <img src={questionadd} alt="interview_image" />
          </div>
          {/* _______________________end__________________________ */}
          <div className="interviewquestion-section-add-interview-content-content-section">
            <h3>Have You Faced Interview Questions?</h3>
            <div className="add-interview-enquiry-section-points">
              <div className="add-interview-enquiry-section-point-icons">
                <CheckCircleOutlineIcon className="add-interview-buttoniconspoints-enquiry" />
                Share Your Personal Experience Include questions you've faced
                during interviews to help others prepare
              </div>
              <div className="add-interview-enquiry-section-point-icons">
                <CheckCircleOutlineIcon className="add-interview-buttoniconspoints-enquiry" />
                Include the Expected Answer or a Hint Providing hints or
                expected answers can make the question more valuable
              </div>
              <div className="add-interview-enquiry-section-point-icons">
                <CheckCircleOutlineIcon className="add-interview-buttoniconspoints-enquiry" />
                Mention the Company or Role Adding context about the company or
                role makes your question more relevant
              </div>
            </div>
            <div className="interview-question-button-container-add-questions">
              <div
                className="button-container-interview-questions"
                onClick={handleOpenModal}
              >
                Share Experience
                <QuestionMarkIcon className="buttonicons-common-enquiry" />
              </div>
            </div>
            {/* ______________general information madal_____________ */}
            <InterviewQuestionGenaralInfoAddModal
              open={open}
              setOpen={setOpen}
              handleCloseModal={handleCloseModal}
            />
            {/* _________________End________________________ */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InterviewQuestionAddQuestion;
