import React, { useEffect, useRef } from "react";
import "./HireTalent.css";
import IndustryReady from "./HireTalentImages/IndustryReady.jpg";
import LatestTechnologies from "./HireTalentImages/LatestTechnologies1.jpg";
import SoftSkills from "./HireTalentImages/SoftSkills.jpg";

function HireBenifits() {
  const benefitRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the element is visible
    );

    benefitRefs.current.forEach((benefit) => {
      if (benefit) {
        observer.observe(benefit);
      }
    });

    return () => {
      benefitRefs.current.forEach((benefit) => {
        if (benefit) {
          observer.unobserve(benefit);
        }
      });
    };
  }, []);

  return (
    <div>
      <div className="Hire-benefits-main">
        <div className="Hire-benefits-container">
          <div className="Hire-benefits-heading">
            <h2>Benefits of Hiring from Us</h2>
          </div>
          <div className="Hire-benefits-content">
            <div
              className="Hire-benefit"
              ref={(el) => (benefitRefs.current[0] = el)}
            >
              <img src={IndustryReady} alt="Talent Pool" />
              <h3>Industry-Ready</h3>
              <p>
                Our candidates are trained and prepared to enter the workforce
                immediately.
              </p>
            </div>
            <div
              className="Hire-benefit"
              ref={(el) => (benefitRefs.current[1] = el)}
            >
              <img src={LatestTechnologies} alt="Talent Pool" />
              <h3>Latest Technologies</h3>
              <p>
                We ensure our candidates are up-to-date with the latest tools
                and technologies.
              </p>
            </div>
            <div
              className="Hire-benefit"
              ref={(el) => (benefitRefs.current[2] = el)}
            >
              <img src={SoftSkills} alt="Talent Pool" />
              <h3>Soft Skills</h3>
              <p>
                Our training includes essential soft skills, making our
                candidates well-rounded professionals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HireBenifits;
