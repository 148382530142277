import React, { useState, useEffect } from "react";
import HeaderMain from "../Header/HeaderMain";
import Teach_with_us from "./Teach_with_us";
import TOT_Component_3 from "./TOT_Component_3";
import TOT_Component_4 from "./TOT_Component_4";
import Teach3InstructorFAQ from "./Teach3InstructorFAQ";
import FooterMain from "../Footer/FooterMain";
import BackToTop from "../BacktoTop/BackToTop";
import PageLoading from "../PageLoading/PageLoading";
import HeaderSmallBar from "../Home/HeaderTopSmallBar/HeaderSmallBar";
import { baseURL } from '../../http'
import axios from 'axios';
import WhatsappButton from "../Whatsapp/WhatsappButton";

function TeachUsOnTPAMain() {
  const [isLoading, setIsLoading] = useState(true);
  const [faqData, setFaqData] = useState([]);

  //-------------------------------------------------------------------------
  //Page Loading and FAQ data from backend
  //-------------------------------------------------------------------------
  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await axios.get(`${baseURL}/FAQ_fetch_all`);
        const data = response.data;
  
        const approvedFaqs = data
          .filter((faq) => faq.IsApproved === "1")
          .filter((faq) => faq.Category.includes("Teach on TPA"));
  
        const mappedFaqData = approvedFaqs.map((faq) => ({
          question: faq.Question,
          answer: faq.Answer,
        }));
  
        console.log("Mapped FAQ Data:", mappedFaqData);
        setFaqData(mappedFaqData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching FAQ data:", error);
        setIsLoading(false);
      }
    };
  
    fetchFAQs();
  }, []);

  

  return (
    <div>
      {isLoading ? (
        <PageLoading image="TalentPoolAcademy_logo.png" />
      ) : (
        <>
          <HeaderSmallBar />
          <HeaderMain />
          <TOT_Component_4 />
          <TOT_Component_3 />
          <Teach_with_us />
          <Teach3InstructorFAQ
            faqData={faqData}
            headerName={"Instructor FAQs"}
          />
          <FooterMain />
          <WhatsappButton />
          <BackToTop />
        </>
      )}
    </div>
  );
}

export default TeachUsOnTPAMain;
