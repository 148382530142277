import React, { useState, useMemo, useCallback, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CourseCard from "../../CourseCard/CourseCard";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../http";
import "./homecoursecardrender.css";

const responsive = {
  superLargeDesktop: { breakpoint: { max: 3000, min: 1100 }, items: 4 },
  desktop: { breakpoint: { max: 1100, min: 860 }, items: 3 },
  tablet: { breakpoint: { max: 860, min: 550 }, items: 2 },
  mobile: { breakpoint: { max: 550, min: 0 }, items: 1 },
};

function HomeCourseCardReder({ structuredData }) {
  const [allCourses, setAllCourses] = useState([]);
  const categories = useMemo(() => ["All", ...Object.keys(structuredData)], [structuredData]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedSubcategory, setSelectedSubcategory] = useState("All");
  const navigate = useNavigate();

  console.log(selectedCategory,selectedSubcategory)

  // Fetch all courses data once
  useEffect(() => {
    const fetchAllCourses = async () => {
      try {
        const response = await axios.get(`${baseURL}/coursepage_fetch_all`);
        setAllCourses(response.data);
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    fetchAllCourses();
  }, []);

  const filteredCourses = useMemo(() => {
    let courseNames = [];
  
    // Get course names based on selected category and subcategory
    if (selectedCategory === "All") {
      courseNames = Object.values(structuredData).flatMap(categoryData => Object.values(categoryData).flat());
    } else if (selectedSubcategory === "All") {
      courseNames = Object.values(structuredData[selectedCategory] || {}).flat();
    } else if (structuredData[selectedCategory] && structuredData[selectedCategory][selectedSubcategory]) {
      courseNames = structuredData[selectedCategory][selectedSubcategory];
    }
  
    // Filter allCourses by course names obtained from structuredData
    return allCourses.filter(course => courseNames.includes(course.CourseName));
  }, [selectedCategory, selectedSubcategory, structuredData, allCourses]);
  

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory("All");
  };

  const handleSubcategoryClick = (subcategory) => setSelectedSubcategory(subcategory);

  const handleToRouteAllCourse = useCallback(() => navigate("/AllCourse"), [navigate]);

  // Navigate to specific course based on selectedCategory and courseName
  const handleCourseClick = useCallback(
    (courseName) => navigate(`/course/${selectedCategory}/${courseName}`),
    [navigate, selectedCategory]
  );

  return (
    <div className="home-card-render-section-background-color">
      <div className="home-card-render-section-width-control-container">
        <div className="Header-and-tagline-conatiner">
          <div className="course-header-and-button">
            <div className="course-card-render-header">Skills at Your Fingertips</div>
            <div className="home-course-section-more-review-button" onClick={handleToRouteAllCourse}>
              All Courses
            </div>
          </div>
        </div>

        {/* Categories Tabs */}
        <div className="tab-container">
          {categories.length > 0 ? (
            categories.map((category) => (
              <div
                key={category}
                className={`tab-item ${category === selectedCategory ? "active" : ""}`}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </div>
            ))
          ) : (
            <div className="no-courses-message">No categories available</div>
          )}
        </div>

        {/* Subcategories Tabs */}
        {selectedCategory !== "All" && selectedCategory && structuredData[selectedCategory] ? (
          <div className="subcategory-container">
            <div
              className={`subcategory-item ${selectedSubcategory === "All" ? "active" : ""}`}
              onClick={() => handleSubcategoryClick("All")}
            >
              All
            </div>
            {Object.keys(structuredData[selectedCategory]).map((subcategory) => (
              <div
                key={subcategory}
                className={`subcategory-item ${subcategory === selectedSubcategory ? "active" : ""}`}
                onClick={() => handleSubcategoryClick(subcategory)}
              >
                {subcategory}
              </div>
            ))}
          </div>
        ) : null}

        {/* Carousel of Course Cards */}
        {filteredCourses.length > 0 ? (
          <Carousel showDots={true} renderDotsOutside={false} itemClass="carousel-item-padding-30-px" autoPlaySpeed={1500} loop={true} autoPlay={true} infinite={true} responsive={responsive} lazyLoad={true}>
            {filteredCourses.map((course) => (
              <CourseCard key={course.CourseName} courseData={course} onClick={() => handleCourseClick(course.CourseName)} />
            ))}
          </Carousel>
        ) : (
          <div className="no-courses-message">No courses available</div>
        )}
      </div>
    </div>
  );
}

export default HomeCourseCardReder;
