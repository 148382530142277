import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import { Modal, Tooltip, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./HeaderMain.css";

function HamburgerMenu({ NavItems, subCategories }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  //-------------------------------------------------------------------------
  //click to open the mobile Menu
  //-------------------------------------------------------------------------
  const toggleMenu = () => {
    setMenuOpen(true);
  };

  //-------------------------------------------------------------------------
  //close the mobile Menu
  //-------------------------------------------------------------------------

  const closeModal = () => {
    setMenuOpen(false);
  };

  //-------------------------------------------------------------------------
  //Handle function  to route the Course
  //-------------------------------------------------------------------------
  const handleCourseClick = (courseName, category) => {
    navigate(`/course/${category}/${courseName}`);
    closeModal();
  };

  //-------------------------------------------------------------------------
  //Filter courses based on search query
  //-------------------------------------------------------------------------
  const filteredCourses = () => {
    let filtered = {};
    for (const category in subCategories) {
      for (const subCategory in subCategories[category]) {
        const filteredSubCategory = subCategories[category][subCategory].filter(
          (course) => course.toLowerCase().includes(searchQuery.toLowerCase())
        );
        if (filteredSubCategory.length > 0) {
          if (!filtered[category]) filtered[category] = {};
          filtered[category][subCategory] = filteredSubCategory;
        }
      }
    }
    return filtered;
  };

  //-------------------------------------------------------------------------
  //Calculate total number of course names
  //-------------------------------------------------------------------------
  const totalCourseCount = () => {
    let count = 0;
    const filtered = filteredCourses();
    for (const category in filtered) {
      for (const subCategory in filtered[category]) {
        count += filtered[category][subCategory].length;
      }
    }
    return count;
  };

  return (
    <div className="hamburgerMenu">
      {/* ______________Close and Menu icon section____________ */}
      <button className="menuButton" onClick={toggleMenu}>
        {menuOpen ? (
          <CloseIcon />
        ) : (
          <MenuIcon className="hamburg-menu-icon-size" />
        )}
      </button>
      {/* ____________________Modal____________________ */}
      <Modal open={menuOpen} onClose={closeModal}>
        <div className="modalContentMobileView ">
          <div className="modalHeaderMobileView">
            {/* ______________Homeicon,heading,closeicon section___________ */}
            <div className="hamburgmenu-home-icon-and-text">
              <a href="/home" className="customLink1">
                <HomeIcon className="hamburgmenu-home-icon" />
                <span className="Home-text-alignment">Home</span>
              </a>
            </div>
            <div className="hamburg-close-icon-container">
              <Tooltip title="Close" arrow>
                <CloseIcon className="hamburg-closeIcon" onClick={closeModal} />
              </Tooltip>
            </div>
          </div>
          {/* ___________________________end_________________________ */}

          {/* _____________________Searchcontainer___________________ */}
          <div className="hamburg-search-container">
            <TextField
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search courses..."
              className="hamburg-searchBox"
            />
          </div>
          {/* _____________________________end_______________________ */}
          {/* ______________________Course name Section_____________ */}
          <div>
            <div className="hamburg-heading-course">
              All Courses ({totalCourseCount()})
            </div>
          </div>
          <div className="hamburg-coursename-render-container">
            {Object.keys(filteredCourses()).map((category, index) => (
              <div key={index}>
                {Object.keys(filteredCourses()[category]).map(
                  (subCategory, subIndex) => (
                    <div key={subIndex}>
                      {filteredCourses()[category][subCategory].map(
                        (courseName, courseIndex) => (
                          <div
                            key={courseIndex}
                            onClick={() =>
                              handleCourseClick(courseName, category)
                            }
                            className="hamburg-course-name-container"
                          >
                            {courseName}
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
          {/* ________________________End___________________________ */}
          {/* _________________________Our product section___________ */}
          <div className="hamburg-our-product-outer-container">
            <div className="hamburg-heading">Our Product</div>
            <div className="hamburg-our-product-conatiner">
              {NavItems.map((item, index) => (
                <div key={index} className="navItem">
                  <a href={`${item.Link}`}>{item.name}</a>
                </div>
              ))}
            </div>
          </div>
          {/* _____________________End_______________________________ */}
        </div>
      </Modal>
    </div>
  );
}

export default HamburgerMenu;
